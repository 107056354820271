import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import AiChatCtrl from './aiChatCtrl';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { isMobileDevice } from '../utils';

interface Props {
    visible: boolean;
    title: string;
    label: string;
    avatarImageurl?: string;
    onClose: () => void;
}

const AiChatDialog: React.FC<Props> = ({ visible, title, label, avatarImageurl, onClose }) => {

    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={visible}
            maxWidth="lg"
            fullWidth={true}
            fullScreen={isMobileDevice()}
        //disableEnforceFocus={true}
        >
            <DialogTitle id="customized-dialog-title">
                {title}
            </DialogTitle>

            <DialogContent
                dividers                
            >

                <Grid container>
                    <Grid item xs={12}>
                        <span>{label}</span>
                    </Grid>
                    <Grid item xs={12} textAlign={'center'}>
                        <AiChatCtrl avatarImageUrl={avatarImageurl} />
                    </Grid>
                </Grid>

            </DialogContent>

            <DialogActions>
                <Button onClick={() => onClose()} color="primary" >
                    Chat beenden
                </Button>
            </DialogActions>

        </Dialog>

    );
}

export default AiChatDialog;
