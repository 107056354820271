import React, { useEffect } from 'react';

import {Link} from "react-router-dom";
// import { analytics } from '../database';

export default function LegalNoticePage() {

    // useEffect(()=>{
    //     analytics.logEvent("page_view", {page_title: "Legal Notice"});
    // },[]);

    return (
        <div className="kt-legalNoticePage">

            <div className="kt-body">
                <div className="kt-panel">

                    <div className="kt-content-header">

                        <div className="kt-history-back-link" onClick={e => window.history.back()}><i className="far fa-angle-left"></i> Zurück</div>

                        <h2><strong>Impressum</strong></h2>

                        <div>
                            <p>
                                <h3>Pickadoc GmbH</h3>
                                Oststrasse 34a<br/>
                                40211 Düsseldorf<br/>
                            </p>
                            <p>
                                Tel.: 0211 - 3029 3035<br/>
                                <a href="mailto:info@pickadoc.de">info@pickadoc.de</a>
                            </p>

                            <p>
                                <strong>Geschäftsführer: Dr. Michael Petsas</strong><br/>
                                Amtsgericht Düsseldorf, HRB: 99537<br/>
                                {/* Ust-ID: DE XXXXXX<br/> */}
                            </p>

                            <p>
                                <Link to="/terms">AGB</Link><br/>
                                <Link to="/data-protection">Datenschutzerklärung</Link>
                            </p>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}