import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import GPTChatMessage from "../models/gptChatMessage";
import firebaseApp from "./../components/database";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions(firebaseApp, 'europe-west3');

const db = getFirestore(firebaseApp);

const AiChatsService = {

    startGPTChat(requestId: string, messages: GPTChatMessage[]): void {

        console.log(requestId);

        const getGPTChatResponse = httpsCallable(functions, 'getGPTChatResponse');
        getGPTChatResponse(
            {
                requestId: requestId,
                messages: messages
            }
        );
    },

    listenForGPTChatResponse(requestId: string, changeCallback: (message: GPTChatMessage | null, finishReason: string) => void): () => void {

        return onSnapshot(doc(db, "gptStreams", requestId), doc => {

                try {

                    if (doc.exists()) {
                        const _doc = doc.data();
                        if (_doc) {
                            const _message: GPTChatMessage = {
                                content: _doc.content,
                                role: "assistant"
                            }
                            changeCallback(_message, _doc.finishReason);
                            return;
                        }
                    }

                } catch (err) {
                    console.error("error in listenForGPTChatResponse:" + err);
                }

                changeCallback(null, "");

            });
    }
}

export default AiChatsService;