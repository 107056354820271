import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import RegisterUserPanel from "../registerUserPanel";
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const RegisterPage: React.FC = () => {

    const { appointmentId } = useParams();
    const navigate = useNavigate();

    const [authUser, setAuthUser] = useState<any|null>(null);


    useEffect(() => {
        return onAuthStateChanged(getAuth(), user => {
            if (user) {
                // User is signed in.
                setAuthUser(user);

                if(user && user.phoneNumber) {
                    if(appointmentId){
                        navigate(`/confirmation/${appointmentId}`);
                    } else {
                        setTimeout(() => {
                            navigate(`/appointments`);
                        }, 1000);
                    }
                }

            } else {
                // User is signed out.
                setAuthUser(null);
            }
        });

        // eslint-disable-next-line
    }, []);




    return (

        <div className="kt-loginPage">

            <div className="kt-body">

                {(authUser && authUser.phoneNumber) &&
                <div className="kt-panel">
                    <div className="kt-panel-content">
                        <div><h2>Sie sind angemeldet.</h2></div>
                        <div className="kt-history-back-link" onClick={e => window.history.back()}><i className="far fa-angle-left"></i> Zurück</div>
                    </div>
                </div>}

                {!(authUser && authUser.phoneNumber) && <RegisterUserPanel />}


            </div>

        </div>
    );
}

export default RegisterPage;