import React, { useState, useEffect } from 'react';

import axios from 'axios';
import TranslationsService from '../../../services/translationsService';
import LeadsService from '../../../services/leadsService';
import Lead, { LeadSourceType } from '../../../models/lead';


interface Props {
    voiceId: string
}



const VoicePlayback: React.FC<Props> = ({ voiceId }) => {

    const XI_API_KEY = "cc9c6633f7b52dc8ba0b6facba2cd6ed";
    const text_to_speech_url = "https://api.elevenlabs.io/v1/text-to-speech/";
    const delete_voice_url = "https://api.elevenlabs.io/v1/voices/";
    const defaultText = "Bei einer Zahnwurzelentzündung sind vor allem das Zahnmark und Nervengewebe im Zahninneren durch Bakterien geschädigt.";
    const maxAudiosAllowed = 5;

    const [isSaving, setIsSaving] = useState(false);

    const [text, setText] = useState(defaultText);
    const [translation, setTranslation] = useState("");

    const [audioURL, setAudioURL] = useState("");

    const [targetLanguage, setTargetLanguage] = useState("de");

    const [audioCounter, setAudioCounter] = useState(0);

    const [formFilled, setFormFilled] = useState(false);

    useEffect(() => {

        const _formFilled = localStorage.getItem("formFilled");
        if (_formFilled) {
            setFormFilled(true);
        }

        const _counter = localStorage.getItem("audioCounter");
        if (_counter) {
            setAudioCounter(parseInt(_counter));
        }
    }, []);

    function resetAudioCounter() {
        localStorage.setItem("audioCounter", "0");
        setAudioCounter(0);
        setText(defaultText);
    }

    async function generateAudio() {

        if (text.length < 50) {
            alert("Bitte geben Sie zuerst etwas Text ein. (mindestens 50 Zeichen)");
            return
        }

        if (text === "reset audio") {
            resetAudioCounter();
            return;
        }

        setIsSaving(true);

        setTranslation("");
        setAudioURL("");

        const transl = await TranslationsService.translate(text, targetLanguage);
        setTranslation(transl);

        console.log("lang: " + targetLanguage + " translation: " + transl);

        // Set options for the API request.
        const options = {
            method: 'POST',
            url: text_to_speech_url + voiceId,
            headers: {
                accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
                'content-type': 'application/json', // Set the content type to application/json.
                'xi-api-key': `${XI_API_KEY}`, // Set the API key in the headers.
            },
            data: {
                text: transl, // Pass in the inputText as the text to be converted to speech.
                "model_id": "eleven_multilingual_v2",
            },
            responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
        } as any;

        // Send the API request using Axios and wait for the response.
        const speechDetails = await axios.request(options);


        // Create a new Blob object from the audio data with MIME type 'audio/mpeg'
        const blob = new Blob([speechDetails.data], { type: 'audio/mpeg' });
        // Create a URL for the blob object
        const url = URL.createObjectURL(blob);
        // Set the audio URL state variable to the newly created URL
        setAudioURL(url);

        // disabled counter for trade show "medica 2024"
        // const newCount = audioCounter + 1
        // localStorage.setItem("audioCounter", newCount.toString());
        // setAudioCounter(newCount);

        setIsSaving(false);

    }

    async function deleteVoice(voiceId: string) {

        // Set options for the API request.
        const options = {
            method: 'DELETE',
            url: delete_voice_url + voiceId,
            headers: {
                'content-type': 'application/json', // Set the content type to application/json.
                'xi-api-key': `${XI_API_KEY}`, // Set the API key in the headers.
            }
        } as any;

        await axios.request(options);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsSaving(true);

        const newLead = new Lead();
        newLead.firstName = (document.getElementById("firstName") as HTMLInputElement).value;
        newLead.lastName = (document.getElementById("lastName") as HTMLInputElement).value;
        newLead.mobilePhone = (document.getElementById("mobilePhone") as HTMLInputElement).value;
        newLead.source = LeadSourceType.clonrVoicePlaygroundForm;
        newLead.playgroundLink = window.location.href;

        await LeadsService.addLead(newLead);

        localStorage.setItem("formFilled", "true");
        setFormFilled(true);

        deleteVoice(voiceId);

        setIsSaving(false);

    }

    function restart() {
        window.location.reload();
    }

    if (isSaving) {
        return <div className='kt-box-row-wrapper'>

            <div className='kt-box-row kt-box-features' style={{ margin: 0 }}>
                <div className='kt-box-row-content kt-big' style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

                    <img src="../images/ai-avatar.JPG" alt="AI avatar"></img>

                    <br />

                    <h1 style={{ textAlign: "center" }}>Bitte haben Sie einen Moment Geduld...</h1>

                </div>
            </div>
        </div>
    }

    if (formFilled) {
        return <div className='kt-box-row-wrapper'>

            <div className='kt-box-row kt-box-features' style={{ margin: 0 }}>
                <div className='kt-box-row-content kt-big' style={{ width: "100%", maxWidth: "100%", flexDirection: "column", alignItems: "center" }}>

                    <img src="../images/ai-avatar.JPG" alt="AI avatar"></img>

                    <br />

                    <h1 style={{ textAlign: "center" }}>Vielen Dank! Wir melden uns umgehend bei Ihnen.</h1>

                </div>
            </div>
        </div>
    }

    if (audioCounter >= maxAudiosAllowed + 1) {
        return <div className='kt-box-row-wrapper'>

            <div className='kt-box-row kt-box-features' style={{ margin: 0 }}>
                <div className='kt-box-row-content kt-big' style={{ width: "100%", maxWidth: "100%", flexDirection: "column", alignItems: "center" }}>

                    <img src="../images/ai-avatar.JPG" alt="AI avatar"></img>

                    <br />

                    <h1 style={{ textAlign: "center" }}>Sind Sie neugierig auf mehr?</h1>

                    <p>Nehmen Sie Kontakt mit uns auf:</p>

                    <form onSubmit={handleSubmit}>

                        <div className='kt-form-ctrl'>
                            <input id="firstName" type='text' placeholder='Vorname' required style={{ marginLeft: 0 }}></input>
                        </div>
                        <div className='kt-form-ctrl'>
                            <input id="lastName" type='text' placeholder='Nachname' required style={{ marginLeft: 0 }}></input>
                        </div>
                        <div className='kt-form-ctrl'>
                            <input id="email" type='email' placeholder='Email' required style={{ marginLeft: 0 }}></input>
                        </div>
                        <div className='kt-form-ctrl'>
                            <input id="mobilePhone" type='tel' placeholder='Handynummer' required style={{ marginLeft: 0 }}></input>
                        </div>

                        <div className="kt-form-ctrl">
                            <button className='kt-btn' >ABSENDEN</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    }


    return (

        <div>

            <div className='kt-box-row kt-box-features' style={{ margin: 0 }}>
                <div className='kt-box-row-content kt-big' style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

                    {/* <div className='kt-form-ctrl'>
                        <label htmlFor='voice'>Stimme: </label>
                        <select name="voice" value={selectedVoiceId} onChange={e => setSelectedVoiceId(e.currentTarget.value)} required>
                            <option value={""}>-- Bitte Auswahl treffen --</option>

                            {voices.map(voice =>
                                <option key={voice.voice_id} value={voice.voice_id}>{voice.name}</option>
                            )}
                        </select>
                    </div> */}

                    <br />
                    <div>
                        {/* Noch {maxAudiosAllowed - audioCounter} von {maxAudiosAllowed} Audios übrig */}
                    </div>
                    <div className="kt-form-ctrl">
                        <textarea style={{maxWidth: "calc(100vw - 160px)"}} rows={6} cols={40} maxLength={150} value={text} onChange={e => setText(e.currentTarget.value)}></textarea>
                    </div>

                    <div className='kt-country-flags' style={{ marginBottom: "20px" }}>
                        <div className={targetLanguage === "de" ? 'kt-selected' : ""} onClick={() => setTargetLanguage("de")}>
                            <img src="../images/flags/de.svg" alt='germany'></img>
                        </div>
                        <div className={targetLanguage === "en" ? 'kt-selected' : ""} onClick={() => setTargetLanguage("en")}>
                            <img src="../images/flags/en.svg" alt='english'></img>
                        </div>
                        <div className={targetLanguage === "el" ? 'kt-selected' : ""} onClick={() => setTargetLanguage("el")}>
                            <img src="../images/flags/el.svg" alt='greek'></img>
                        </div>
                        <div className={targetLanguage === "fr" ? 'kt-selected' : ""} onClick={() => setTargetLanguage("fr")}>
                            <img src="../images/flags/fr.svg" alt='french'></img>
                        </div>
                        <div className={targetLanguage === "it" ? 'kt-selected' : ""} onClick={() => setTargetLanguage("it")}>
                            <img src="../images/flags/it.svg" alt='italian'></img>
                        </div>
                        <div className={targetLanguage === "es" ? 'kt-selected' : ""} onClick={() => setTargetLanguage("es")}>
                            <img src="../images/flags/es.svg" alt='spanish'></img>
                        </div>
                        <div className={targetLanguage === "pt" ? 'kt-selected' : ""} onClick={() => setTargetLanguage("pt")}>
                            <img src="../images/flags/pt.svg" alt='portuguese'></img>
                        </div>
                        <div className={targetLanguage === "pl" ? 'kt-selected' : ""} onClick={() => setTargetLanguage("pl")}>
                            <img src="../images/flags/pl.svg" alt='polish'></img>
                        </div>
                        <div className={targetLanguage === "hi" ? 'kt-selected' : ""} onClick={() => setTargetLanguage("hi")}>
                            <img src="../images/flags/hi.svg" alt='hindi'></img>
                        </div>
                        {/* <div className={targetLanguage === "ar" ? 'kt-selected' : ""} onClick={() => setTargetLanguage("ar")}>
                            <img src="../images/flags/arab_league.svg" alt='arabic'></img>
                        </div>
                        <div className={targetLanguage === "ru" ? 'kt-selected' : ""} onClick={() => setTargetLanguage("ru")}>
                            <img src="../images/flags/ru.svg" alt='russian'></img>
                        </div> */}
                    </div>

                    <div className="kt-form-ctrl" style={{ display: "flex", flexDirection: "column", alignContent: "center" }}>
                        {isSaving ?
                            <button className='kt-btn'><i className='fad fa-spinner fa-spin'></i> Audio wird erstellt...</button>
                            :
                            <button className='kt-btn' onClick={generateAudio}>Erstellen</button>
                        }
                    </div>

                    {audioURL && (<>
                        <audio autoPlay controls>
                            <source src={audioURL} type="audio/mpeg" />
                            <a href={audioURL}>Herunterladen</a>
                        </audio>
                    </>
                    )}

                    {translation &&
                        <div className="kt-form-ctrl">
                            <textarea style={{maxWidth: "calc(100vw - 160px)"}} rows={6} cols={40} value={translation} disabled></textarea>
                        </div>}

                    <div className="kt-form-ctrl" style={{ display: "flex", flexDirection: "column", alignContent: "center" }}>
                        {!isSaving && <button className='kt-btn' style={{backgroundColor: "#24374e"}} onClick={restart}>NEU STARTEN</button>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VoicePlayback;