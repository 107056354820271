import React from 'react';

const Error404Page: React.FC = () => {

    return (
        <div className="kt-body">
            <div className="kt-panel">
                <div className="kt-panel-content">
                    <h1>Ups, da ist was schief gelaufen!!</h1>
                    <h2>Wir konnten die von Ihnen angeforderte Seite nicht finden.</h2>
                    <h2>Bitte überprüfen Sie die URL.</h2>
                </div>
            </div>
        </div>
    );
}

export default Error404Page;