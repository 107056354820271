import React from 'react';
import { useEffect, useState} from 'react';
import { useParams } from "react-router-dom";
import RatingsService from '../../services/ratingsService';
import StarsCtrl from '../starsCtrl';
import { getDateString } from '../../utils';
import Avatar from "./../avatar";
import UsersService from '../../services/usersService';
import ClientsService from '../../services/clientsService';

export default function RatingPage() {

    const { ratingId } = useParams();

    const [ratingValue, setRatingValue] = useState(0);
    const [ratingText, setRatingText] = useState("");
    const [canSubmit, setCanSubmit] = useState(false);

    const [doctorAvatarUrl, setDoctorAvatarUrl] = useState("");
    const [clientLogoUrl, setClientLogoUrl] = useState("");
    const [rating, setRating] = useState<any>(null);

    const [pageState, setPageState] = useState<"waitForUserInput" | "loading" | "saving" | "alreadyRated" | "thankYou" | "invalid">("loading");

    useEffect(()=>{

        if(ratingId){
            loadRating();
        }

    }, [ratingId]);

    useEffect(()=> {

        if(pageState === "waitForUserInput") {
            setCanSubmit(ratingValue > 0 && ratingText.length > 3 && ratingText.length <= 1000);
        } else if (pageState === "saving"){
            setCanSubmit(false);
        }

    }, [ratingValue, ratingText, pageState]);

    async function loadRating() {

        setPageState("loading");

        const _rating = await RatingsService.getRating(ratingId);
        if(_rating) {
            setRating(_rating);

            setRatingValue(_rating.rating);

            const _doctorAvatarUrl = await UsersService.getUserAvatarUrl(_rating.clientId, _rating.doctorId, "");
            setDoctorAvatarUrl(_doctorAvatarUrl);

            const _clientLogoUrl = await ClientsService.getClientLogoUrl(_rating.clientId);
            setClientLogoUrl(_clientLogoUrl);

            if(!_rating.rating) {
                setPageState("waitForUserInput");
            } else {
                setPageState("alreadyRated");
            }

        } else {
            setPageState("invalid");
        }
    }

    async function onFormSubmit(event){

        if(event){
            event.preventDefault();
        }

        setPageState("saving");

        // save rating
        rating.rating = ratingValue;
        rating.comment = ratingText;
        rating.ratedAt = new Date();

        await RatingsService.setRating(rating);

        setPageState("thankYou");
    }

    function renderDoctorInfoBox(){
        return (
            <div className="kt-doctor-wrapper">
                <div>{doctorAvatarUrl && <Avatar src={doctorAvatarUrl} alt="" width="80px"/>}</div>
                <div>
                    <div className="kt-center">{rating?.doctorName} erhält von Ihnen</div>
                    <StarsCtrl
                        rating={ratingValue}
                        onRatingChange={(newRating) => setRatingValue(newRating)}
                        readOnly={rating.ratedAt !== null}
                    />
                </div>


            </div>
        );
    }

    function getPatientName(): string {
        if(rating){
            return `Sehr ${rating.patientGender === "f" ? "geehrte Frau" : "geehrter Herr"} ${rating.patientFirstName} ${rating.patientLastName}`
        }

        return "";
    }

    function renderRatingForm(){

        if(!rating) return;

        return (
            <form name="rate" onSubmit={onFormSubmit}>

                <h2><strong>Ihre Meinung zählt</strong></h2>
                <span>{getPatientName()}, helfen Sie uns besser zu werden</span>

                {renderDoctorInfoBox()}

                <textarea
                    maxLength={1000}
                    minLength={3}
                    name="ratingText"
                    rows={5}
                    placeholder={`Teilen Sie Ihre Erfahrungen für Ihren Termin ${rating?.visitMotiveName} vom ${getDateString(rating?.appointmentDate)}.`}
                    required
                    value={ratingText}
                    onChange={e => setRatingText(e.target.value)}
                />

                <input
                    className={canSubmit ? "kt-btn-big" : "kt-btn-big kt-btn-disabled"}
                    type="submit"
                    value="BEWERTEN"
                    disabled={!canSubmit}
                />
            </form>
        );

    }

    function renderAlreadyRatedMessage() {
        return (
            <div className="kt-center">
                <h2><strong>Vielen Dank!</strong></h2>
                {renderDoctorInfoBox()}
                <p>Sie haben Ihre Behandlung vom <span className="kt-highlight">{getDateString(rating.appointmentDate)}</span> schon bewertet.</p>
            </div>
        );
    }

    function renderThankYouMessage() {
        return (
            <div className="kt-center">
                <h2><strong>Vielen Dank!</strong></h2>
                <p>Wir haben Ihre Bewertung erhalten.</p>
            </div>
        );
    }

    function renderLoadingMessage() {
        return (
            <div>
                <h2><strong>Lade Bewertung...</strong></h2>
            </div>
        );
    }

    function renderSavingMessage() {
        return (
            <div>
                <h2><strong>Ihre Bewertung wird gespeichert...</strong></h2>
            </div>
        );
    }

    function renderInvalidMessage() {
        return (
            <div>
                <h2><strong>Ungültige URL</strong></h2>
                <p>
                    Wir konnten unter der von Ihnen aufgerufenen URL leider keine Bewertung finden.
                </p>
            </div>
        );
    }

    function renderPageContent() {

        switch (pageState) {

            case "waitForUserInput":
                return  renderRatingForm();

            case "alreadyRated":
                return renderAlreadyRatedMessage();

            case "loading":
                return renderLoadingMessage();

            case "saving":
                return renderSavingMessage();

            case "invalid":
                return renderInvalidMessage();

            case "thankYou":
                return renderThankYouMessage();

            default:
                return <div></div>
        }

    }

    return (
        <div className="kt-ratingPage">

            <div className="kt-body">
                <div className="kt-panel">

                    {clientLogoUrl && <div className="kt-logo-wrapper">
                        <img src={clientLogoUrl} alt="Logo"/>
                    </div>}

                    {renderPageContent()}

                </div>
            </div>

        </div>
    );
}
