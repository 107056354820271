import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '../components/database';

const functions = getFunctions(firebaseApp, 'europe-west3');

const TranslationsService = {

    languages: [
        { key: "de", name: "Deutsch" },
        { key: "en", name: "Englisch" },
        { key: "es", name: "Spanisch" },
        { key: "ja", name: "Japanisch" },
        { key: "zh", name: "Chinesisch" },
        { key: "hi", name: "Hindi" },
        { key: "fr", name: "Französisch" },
        { key: "ko", name: "Koreanisch" },
        { key: "el", name: "Griechisch" },
        { key: "tr", name: "Türkisch" },
        { key: "pt", name: "Portugiesisch" },
        { key: "it", name: "Italienisch" },
        { key: "id", name: "Indonesisch" },
        { key: "nl", name: "Niederländisch" },
        { key: "fil", name: "Filipino" },
        { key: "pl", name: "Polnisch" },
        { key: "sv", name: "Schwedisch" },
        { key: "bg", name: "Bulgarisch" },
        { key: "ro", name: "Rumänisch" },
        { key: "ar", name: "Arabisch" },
        { key: "cs", name: "Tschechisch" },
        { key: "fi", name: "Finnisch" },
        { key: "hr", name: "Kroatisch" },
        { key: "ms", name: "Malaiisch" },
        { key: "sk", name: "Slovakisch" },
        { key: "da", name: "Dänisch" },
        { key: "uk", name: "Ukrainisch" },
        { key: "ru", name: "Russisch" }

    ],
    
    async translate(text: string, targetLanguage: string): Promise<string> {

        try {

            const translateFunc = httpsCallable(functions, "translate");
            const result:any = await translateFunc(
                {
                    text: text,
                    targetLanguage: targetLanguage
                });

            return result.data;

        } catch (error) {
            console.log("Error translating: ", error);
            return "";
        }
    }

}

export default TranslationsService;