import React from 'react';

import moment from 'moment';
import "moment/min/locales.min";

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

export function getAge(birthDate) {
    if (birthDate) {
        let timeDiff = Math.abs(Date.now() - birthDate.getTime());
        let age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
        return age;
    }

    return 0;
}

export function getBirthdayString(birthDate) {

    if (birthDate) {
        birthDate = getDate(birthDate);

        if (birthDate) {
            var d = new Date(birthDate);
            if (d.getFullYear() > 1) {
                return getDateString(birthDate) + ` (${getAge(birthDate)})`;
            }
        }
    }

    return "";
}

export function getDateString(inDate) {
    if (inDate) {
        inDate = getDate(inDate);

        if (inDate) {
            var options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            return new Date(inDate).toLocaleDateString(undefined, options);
        }
    }

    return "";
}


export function getTimeString(inDate) {
    if (inDate) {
        inDate = getDate(inDate);

        if (inDate) {
            var options = { hour: 'numeric', minute: '2-digit' };
            return new Date(inDate).toLocaleTimeString(undefined, options);
        }
    }

    return "";
}

export function getDiffInMinutes(start, end) {

    var startDate = getDate(start);
    var endDate = getDate(end);

    var diff = (endDate.getTime() - startDate.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
}

export function getDiffInDays(start, end) {
    return getDiffInMinutes(start, end) / 60 / 24;
}

export function getGenderString(gender) {
    if (gender === "m" || gender === "f") {
        return gender === "m" ? "Herr" : "Frau";
    } else {
        return "";
    }
}

export function getFullUserName(user, withoutSpan) {

    let fullName = "";

    if (user) {
        fullName += user.gender ? getGenderString(user.gender) + " " : "";
        fullName += user.title ? user.title + " " : "";
        fullName += user.firstName ? user.firstName + " " : "";
        fullName += user.lastName ? user.lastName + " " : "";
    }

    return withoutSpan ? fullName : <span>{fullName}</span>
}

export function getUserName(user, withoutSpan) {

    let fullName = "";

    if (user) {
        fullName += user.title ? user.title + " " : "";
        fullName += user.firstName ? user.firstName + " " : "";
        fullName += user.lastName ? user.lastName + " " : "";
    }

    return withoutSpan ? fullName : <span>{fullName}</span>
}

export function getPhoneNumber(patient) {
    return patient.mobilePhoneNumber ? patient.mobilePhoneNumber : patient.phoneNumber;
}

export function getWithLeadingZero(num) {
    return num < 10 ? "0" + num : "" + num;
}

// make sure to always work with a date object and not with the firstore timestamp object
export function getDate(potentialDate) {
    if (typeof potentialDate.getMonth === 'function') {
        return potentialDate
    } else if (typeof potentialDate.toDate === 'function') {
        return potentialDate.toDate();
    } else if (potentialDate.hasOwnProperty("_seconds")) {
        return new Date(potentialDate._seconds * 1000);
    }

    return new Date(potentialDate);
}

export function isSameDay(firstDate, secondDate) {
    return firstDate.getFullYear() === secondDate.getFullYear() &&
        firstDate.getMonth() === secondDate.getMonth() &&
        firstDate.getDate() === secondDate.getDate();
}

export function scrollToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}


export function getWeekDayNames(firstWeekDayIndex, useShortNames) {

    var weekDayNames = useShortNames ? moment.weekdaysShort() : moment.weekdays();

    var result = weekDayNames;

    // check if we have to sort the weekday names
    // default is Su, Mo, Tu, We, Th, Fr, Sa
    if (firstWeekDayIndex > 0) {
        result = weekDayNames.slice(firstWeekDayIndex);
        result = result.concat(weekDayNames.slice(0, firstWeekDayIndex));
    }

    return result;
}

export function getOpeningHoursByDayIndex(openingHours, dayIndex, startIndex) {
    if (openingHours) {

        const tempArr = [openingHours.sunday, openingHours.monday, openingHours.tuesday, openingHours.wednesday, openingHours.thursday, openingHours.friday, openingHours.saturday];

        startIndex = startIndex ? startIndex : 0;

        let tempIndex = dayIndex + startIndex;

        tempIndex = tempIndex > 6 ? tempIndex - 7 : tempIndex;
        tempIndex = tempIndex < 0 ? 0 : tempIndex;

        return tempArr[tempIndex];
    }
}

export function trimPhoneNumberWithoutCountryCode(phoneNumber) {

    if (phoneNumber && typeof phoneNumber === "string") {

        // remove all white space
        phoneNumber = phoneNumber.replace(/\s+/g, '');

        // remove all - / . , # characters
        phoneNumber = phoneNumber.replace(/-+/g, '');
        phoneNumber = phoneNumber.replace(/\/+/g, '');
        phoneNumber = phoneNumber.replace(/\.+/g, '');
        phoneNumber = phoneNumber.replace(/,+/g, '');
        phoneNumber = phoneNumber.replace(/#+/g, '');

    }

    return phoneNumber;
}

export function trimPhoneNumber(phoneNumber) {

    if (phoneNumber && typeof phoneNumber === "string") {

        phoneNumber = trimPhoneNumberWithoutCountryCode(phoneNumber);

        if (phoneNumber.slice(0, 1) === "0") {
            phoneNumber = "+49" + phoneNumber.slice(1, phoneNumber.length);
        }
    }

    return phoneNumber;
}

export function isMobileDevice() {
    var check = false;
    (function (a) { if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0, 4))) check = true; })(navigator.userAgent || navigator.vendor);
    return check;
}

export function isCrawlerUserAgent() {
    var botPattern = "(googlebot\/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)";
    var re = new RegExp(botPattern, 'i');
    var userAgent = navigator.userAgent;
    return re.test(userAgent);
}

export function getUUID() {
    let dt = new Date().getTime();
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        /* tslint:disable:no-bitwise */
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === "x" ? r : (r & 0x3 | 0x8)).toString(16);
        /* tslint:enable:no-bitwise */
    });
    return uuid;
}

export function getYouTubeId(url) {
    var id = '';
    url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
        id = url[2].split(/[^0-9a-z_\-]/i);
        id = id[0];
    }
    else {
        id = url;
    }
    return id;
}