import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import Avatar from "../avatar";
import BookingPanel from "../bookingPanel";

import ClientsService from "../../services/clientsService";
import UsersService from "../../services/usersService";
import PatientsService from "../../services/patientsService";
import ClientLocationsService from "../../services/clientLocationsService";
import { getUserName, scrollToTop, getWithLeadingZero, getOpeningHoursByDayIndex, getDiffInDays } from "./../../utils";


import moment from 'moment';
import CalendarsService from '../../services/calendarsService';
import LoggingService from '../../services/loggingService';
import { LogItemType } from '../../models/logItem';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

export default function DoctorPage() {

    const [isLoading, setIsLoading] = useState(true);

    const [locationLogoUrl, setLocationLogoUrl] = useState("");
    const [client, setClient] = useState<any>({ publicTransports: [], accessInformation: [], openingHours: [] });
    const [location, setLocation] = useState<any>(null);
    const [doctor, setDoctor] = useState<any>(null);
    const [doctors, setDoctors] = useState<any>(null);
    const [calendars, setCalendars] = useState([]);

    const [showOnlyTodayOpeningHours, setShowOnlyTodayOpeningHours] = useState(true);

    const [authUser, setAuthUser] = useState<any>(null);
    const [patient, setPatient] = useState<any>(null);

    let { clientId, locationId, userId } = useParams();


    useEffect(() => {
        setIsLoading(true);

        onAuthStateChanged(getAuth(), async function (user) {
            if (user) {
                // User is signed in.
                setAuthUser(user);

                const result = await PatientsService.getPatient(user.uid)

                setPatient(result ?? null);

            } else {
                // User is signed out.
                setAuthUser(null);
                setPatient(null);
            }
        });


    }, []);

    useEffect(() => {
        scrollToTop();
        setShowOnlyTodayOpeningHours(true);
    }, [clientId, locationId, userId]);

    async function loadAllData() {
        if (clientId && locationId) {

            try {

                const locationData = await ClientLocationsService.getLocation(clientId, locationId)

                if (locationData) {
                    setLocation(locationData);
                    setLocationLogoUrl(locationData.logoUrl);
                }

                const clientData = await ClientsService.getClient(clientId)

                setClient(clientData);

                const usersData = await UsersService.getUsersByLocationId(clientId, locationId);

                if (usersData) {

                    const calendarsData = await CalendarsService.getCalendars(clientId, locationId, true);

                    const onlineUsers: any[] = [];

                    for (let i = 0; i < usersData.length; i++) {
                        const doctor = usersData[i];
                        console.log("doc id: " + calendarsData![0].userId);
                        if (calendarsData?.some(c => c.userId === doctor.id)) {
                            onlineUsers.push(doctor);
                        }
                    }

                    setDoctors(onlineUsers);
                }

            } catch (error) {
                console.error((error as any).message);
            }

            setIsLoading(false);
        }
    }

    useEffect(() => {

        loadAllData();

        console.log("doctorPage.useEffect");

    }, [clientId]);

    useEffect(() => {

        async function setAvatarAndUserDetails() {
            try {
                
                let user: any = null;

                if (userId && clientId) {
                    // get the user details
                    user = await UsersService.getUser(clientId, userId);
                } 

                setDoctor(user);                

                if (clientId && locationId) {
            
                    const lastVisitId = user?.id ?? "profile";
                    const lastVisitInDays = LoggingService.getLastVisitInDays(lastVisitId);
                    
                    if (lastVisitInDays !== 0) {
                        const patientName = (patient && patient.lastName) ? `${patient.firstName} ${patient.lastName} hat ` : "";
                        let doctorName = (user && user.lastName) ? ` von ${user.title} ${user.lastName}` : "";
                        
                        const message = `${patientName}Praxisprofil${doctorName} aufgerufen`;
        
                        LoggingService.log(LogItemType.doctorProfileLoaded, "", patient?.id, user?.id, message, clientId, locationId);
                    }
        
                    LoggingService.storeLastVisitTimestamp(lastVisitId);
                }

            } catch (error) {
                console.error((error as any).message);
            }
        }

        setAvatarAndUserDetails();
    }, [userId, clientId]);



    function getLanguagesString() {
        let langString = "";
        let languages = doctor ? doctor.languages : location?.languages;

        if (languages) {
            let langCount = languages.length;
            for (let i = 0; i < langCount; i++) {
                const language = languages[i];

                if (i === 0) {
                    langString = language;
                } else if (i === langCount - 1 && langCount > 1) {
                    langString += " und " + language;
                } else {
                    langString += ", " + language;
                }
            }
        }

        return langString;
    }

    function getOpeningHoursString(workingDay) {
        if (workingDay && workingDay.open && workingDay.open.start && workingDay.open.end) {

            if (!workingDay.hasOpen) {
                return "Geschlossen";

            } else if (workingDay.hasPause && workingDay.pause && workingDay.pause.start && workingDay.pause.end) {
                let result = `${getWithLeadingZero(workingDay.open.start.hour)}:${getWithLeadingZero(workingDay.open.start.minute)} - `;

                if (workingDay.hasPause && workingDay.pause && workingDay.pause.start && workingDay.pause.end) {
                    result += `${getWithLeadingZero(workingDay.pause.start.hour)}:${getWithLeadingZero(workingDay.pause.start.minute)}, `;
                    result += `${getWithLeadingZero(workingDay.pause.end.hour)}:${getWithLeadingZero(workingDay.pause.end.minute)} - `;
                }

                result += `${getWithLeadingZero(workingDay.open.end.hour)}:${getWithLeadingZero(workingDay.open.end.minute)}`;

                return result;

            } else {
                return `${getWithLeadingZero(workingDay.open.start.hour)}:${getWithLeadingZero(workingDay.open.start.minute)} - ${getWithLeadingZero(workingDay.open.end.hour)}:${getWithLeadingZero(workingDay.open.end.minute)}`;
            }

        } else {
            return "";
        }
    }

    function getOpeningHours() {

        if (location) {


            const mom = moment();
            const allHours = (doctor && doctor.openingHours && doctor.openingHours.enabled) ? doctor.openingHours : location.openingHours;
            const todayIndex = mom.day();

            const weekdays = moment.weekdays();

            if (allHours && allHours.monday) {
                if (showOnlyTodayOpeningHours) {
                    const todayHours = getOpeningHoursByDayIndex(allHours, todayIndex);
                    return <div className="kt-two-small-columns">
                        <div>Heute:</div>{getOpeningHoursString(todayHours)}
                        <div className="kt-arrow-down"><i className="fas fa-sort-down" aria-hidden="true" onClick={() => setShowOnlyTodayOpeningHours(false)}></i></div>
                    </div>

                } else {
                    let result: any[] = [];
                    for (let i = 0; i < 7; i++) {
                        const day = weekdays[i];
                        const dayHours = getOpeningHoursByDayIndex(allHours, i);
                        if (dayHours) {
                            result.push(<div key={i} className="kt-two-small-columns kt-margin-bottom-5px"><div>{day}:</div>{getOpeningHoursString(dayHours)}</div>);
                        }
                    }


                    return result;
                }
            }
        }

        return "";
    }

    function getTeamMembers() {
        if (doctors && doctors.length > 0) {
            return doctors.map((user) =>
                <Link to={`/profile/${clientId}/${locationId}/${user.id}`} key={user.id} className="kt-avatar-columns"><Avatar src={user.avatarUrl} alt="" width="50px" />
                    <div>{user.title} {user.firstName} {user.lastName}</div>
                </Link>
            )
        } else {
            return <div></div>
        }
    }

    if (isLoading) {
        return (
            <div className="kt-doctorPage">
                <div className="kt-body">
                    <div className="kt-panel">
                        <div className="kt-panel-content">
                            <h2><i className="fas fa-circle-notch fa-spin"></i> Lade...</h2>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="kt-doctorPage">

            {(!isLoading && (!client || !location || (userId && !doctor))) ? (
                <div className="kt-body">
                    <div className="kt-panel">
                        <div className="kt-panel-content">
                            <h1>Ups, da ist was schief gelaufen!!</h1>
                            <h2>Wir konnten die von Ihnen angeforderte Seite nicht finden.</h2>
                            <h2>Bitte überprüfen Sie die URL.</h2>
                        </div>
                    </div>
                </div>
            ) :

                (
                    <div className="kt-body">

                        <div className="kt-panel">
                            <div className="kt-panel-content">
                                {userId ?
                                    <div className="kt-profile-top">
                                        <Avatar src={doctor.avatarUrl} alt="" width="100px" />
                                        <h1>{getUserName(doctor)}</h1>
                                    </div>
                                    :
                                    <div className="kt-profile-top">
                                        <Avatar src={locationLogoUrl} alt="" width="100px" fitImage={true} noCircle={true} noBorder={true} />
                                        <h1>{location.name}</h1>
                                    </div>
                                }

                            </div>
                        </div>

                        <BookingPanel
                            client={client}
                            location={location}
                            doctor={doctor}
                            doctors={doctors}
                        />

                        <div className="kt-panel kt-with-icon">
                            <div className="kt-panel-icon"><i className="far fa-clock" aria-hidden="true"></i></div>
                            <div className="kt-content-header">
                                <h2>Öffnungszeiten und Kontakt</h2>

                                <div className="kt-panel-2column-row">

                                    <div className="kt-panel-column">
                                        <h3>Öffnungszeiten</h3>
                                        {getOpeningHours()}
                                    </div>

                                    <div className="kt-panel-column">
                                        <h3>Rufnummern</h3>
                                        <div className="kt-two-small-columns">Telefonnummer: <a href={"tel:" + location.phoneNumber}>{location.phoneNumber}</a></div>
                                        {location.faxNumber && <div className="kt-two-small-columns">Faxnummer: <span>{location.faxNumber}</span></div>}
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div className="kt-panel kt-with-icon">
                            <div className="kt-panel-icon"><i className="fal fa-map-marker-alt" aria-hidden="true"></i></div>
                            <div className="kt-panel-content">
                                <h2>Adresse</h2>
                                <h3><Link to={`/profile/${clientId}/${locationId}`}>{client.name}</Link></h3>
                                {location.street}<br />
                                {location.postalCode} {location.city}
                                <div>
                                    {location.googleMapsLink &&
                                        <a className="kt-btn kt-no-underline" href={location.googleMapsLink} target="_blank" rel="noopener noreferrer">
                                            <i className="fal fa-map-marker-alt" aria-hidden="true"></i>
                                            <span> Routenplaner</span>
                                        </a>
                                    }
                                </div>

                                {location.publicTransports.length > 0 && <>
                                    <h3>Öffentlicher Nahverkehr (ÖPNV)</h3>
                                    {location.publicTransports.map((transport, index) =>
                                        <div key={index}>{transport}</div>
                                    )}
                                </>}

                                {location.accessInformation.length > 0 && <>
                                    <h3>Zugangsinformationen</h3>
                                    {location.accessInformation.map((accessInfo, index) =>
                                        <div key={index}>{accessInfo}</div>
                                    )}
                                </>}

                            </div>
                        </div>


                        {!userId ?
                            <div className="kt-panel kt-with-icon">
                                <div className="kt-panel-icon"><i className="fa fa-user-md" aria-hidden="true"></i></div>
                                <div className="kt-panel-content-header">
                                    <h2>Unser Team</h2>

                                    <div className="kt-panel-2column-row">
                                        {getTeamMembers()}
                                    </div>

                                </div>
                            </div>
                            : ""
                        }

                        <div className="kt-panel kt-with-icon">
                            <div className="kt-panel-icon"><i className="far fa-id-card" aria-hidden="true"></i></div>
                            <div className="kt-panel-content">

                                <h2>{doctor ? "Behandlerprofil" : "Profil"}</h2>
                                {doctor && doctor.profile ?
                                    <div className='display-linebreak'>{doctor.profile}</div>
                                    :
                                    <div className='display-linebreak'>{location.profile}</div>
                                }
                                <div className="kt-panel-row">
                                    {getLanguagesString() &&
                                        <div className="kt-panel-column">
                                            <h3>Sprachen</h3>
                                            {getLanguagesString()}
                                        </div>
                                    }

                                    {location.homepage &&
                                        <div className="kt-panel-column">
                                            <h3>Website</h3>
                                            <a href={location.homepage} target="_blank" rel="noopener noreferrer" >{location.homepage}</a>
                                        </div>
                                    }

                                </div>

                            </div>
                        </div>


                    </div>
                )}


        </div>
    );
}