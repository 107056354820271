import { getFirestore, collection, query, where, doc, getDoc, orderBy, getDocs } from "firebase/firestore";
import firebaseApp from "../components/database";

const db = getFirestore(firebaseApp);

const VisitMotivesService = {

    async getVisitMotive(visitMotiveId, clientId, locationId): Promise<any | null> {

        console.log("VisitMotivesService.getVisitMotive");

        try {

            const docRef = doc(db, "clients", clientId, "locations", locationId, "visitMotives", visitMotiveId);
            const document = await getDoc(docRef);

            if (document.exists) {

                let visitMotive: any = null;

                if (document.exists()) {
                    visitMotive = document.data();
                    visitMotive.id = visitMotiveId;

                    // load now landing page data
                    const successorVMotive = await VisitMotivesService.getSuccessorVisitMotive(visitMotive, clientId, locationId);
                    visitMotive.successorLandingPage = successorVMotive ? successorVMotive.landingPage : null;


                    const recallVMotive = await VisitMotivesService.getRecallVisitMotive(visitMotive, clientId, locationId);
                    visitMotive.recallLandingPage = recallVMotive ? recallVMotive.landingPage : null;


                } else {
                    console.log("getVisitMotive: No such document: " + visitMotiveId);
                }

                return visitMotive;

            } else {
                console.log("getVisitMotive: No such document: " + visitMotiveId);
            }

        } catch (error) {
            console.log("Error getting visit motive: ", error);
        }

        return null;
    },

    async getSuccessorVisitMotive(visitMotive, clientId, locationId): Promise<any | null> {

        if (!visitMotive || !visitMotive.successorEnabled || !visitMotive.successorId) return null;

        console.log("VisitMotivesService.getSuccessorVisitMotive");

        let successorVisitMotive: any = null;

        try {
            const docRef = doc(db, "clients", clientId, "locations", locationId, "visitMotives", visitMotive.successorId);
            const document = await getDoc(docRef);

            if (document.exists()) {

                successorVisitMotive = document.data();
                successorVisitMotive.id = visitMotive.successorId;

            } else {
                console.log("getSuccessorVisitMotive: No such document: " + visitMotive.successorId);
            }

        } catch (error) {
            console.log("Error getting successor visit motive: ", error);
        }

        return successorVisitMotive;

    },

    async getRecallVisitMotive(visitMotive, clientId, locationId): Promise<any | null> {

        if (!visitMotive || visitMotive.recurrenceCount === 0 || !visitMotive.recallId) return null;

        console.log("VisitMotivesService.getRecallVisitMotive");

        let recallVisitMotive: any = null;

        try {

            const docRef = doc(db, "clients", clientId, "locations", locationId, "visitMotives", visitMotive.recallId);
            const document = await getDoc(docRef);

            if (document.exists()) {
                recallVisitMotive = document.data();
                recallVisitMotive.id = visitMotive.recallId;

            } else {
                console.log("getRecallVisitMotive: No such document: " + visitMotive.recallId);
            }


        } catch (error) {
            console.log("Error getting recall visit motive: ", error);
        }

        return recallVisitMotive;

    },


    async getAllVisitMotives(clientId, locationId): Promise<any[] | null> {

        console.log("VisitMotivesService.getAllVisitMotives");

        try {

            const q = query(collection(db, `clients/${clientId}/locations/${locationId}/visitMotives`), orderBy("name"));

            const querySnapshot = await getDocs(q);

            const visitMotiveList: any[] = [];

            querySnapshot.forEach((doc) => {
                if (doc.exists()) {
                    const visitMotive = doc.data();
                    visitMotive.id = doc.id;
                    visitMotiveList.push(visitMotive);
                }
            });

            return visitMotiveList;


        } catch (error) {
            console.log("Error getting visit motives: ", error);
        }

        return null;

    },

    async getOnlineVisitMotives(clientId, locationId): Promise<any[] | null> {

        console.log("VisitMotivesService.getOnlineVisitMotives");

        try {
            const q = query(collection(db, `clients/${clientId}/locations/${locationId}/visitMotives`),
                orderBy("name"),
                where("allowOnlineBooking", "==", true)
            );

            const querySnapshot = await getDocs(q);

            const visitMotiveList: any[] = [];

            querySnapshot.forEach((doc) => {

                const visitMotive = doc.data();

                visitMotive.id = doc.id;
                visitMotiveList.push(visitMotive);
            });


            return visitMotiveList;


        } catch (error) {
            console.log("Error getting visit motives: ", error);
        }

        return null;

    }
}

export default VisitMotivesService;