import React from 'react';

interface Props {
    countryCode: string,
    width?: number
}

const CountryFlagCtrl: React.FC<Props> = ({ countryCode, width }) => {

    if(width) return <img src = {`/images/flags/${countryCode}.svg`} alt = "country flag" width = { width } />

    return <img src = {`/images/flags/${countryCode}.svg`} alt = "country flag" />
    
}

export default CountryFlagCtrl;