import { doc, getDoc, getFirestore } from "firebase/firestore";
import firebaseApp from "../components/database";

const ClientLocationsService = {

    async getLocation(clientId, locationId): Promise<any> {

        try {

            const db = getFirestore(firebaseApp);

            const docRef = doc(db, "clients", clientId, "locations", locationId);
            const document = await getDoc(docRef);
            
            if (document.exists()) {
                const location: any = document.data();
                location.id = locationId;

                // fix, for wrong stored property
                if (location.openingHours) location.openingHours.enabled = true;

                return location;

            } else {
                console.log("getLocation: No such document: " + locationId);
            }

        } catch (error) {
            console.log("Error getting location: ", error);
            return null;
        }

    }

}

export default ClientLocationsService;