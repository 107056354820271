import React, { useEffect, useState } from 'react';
import Gender from '../../models/gender';
import InstallationModel from '../../models/installationModel';
import InstallationsService from '../../services/installationsService';
import UsersService, { UserPreCheckType } from '../../services/usersService';
import { trimPhoneNumber } from '../../utils';
import Popup from '../popup';
import TermsPanel from '../termsPanel';

import { createUserWithEmailAndPassword, getAuth, linkWithPhoneNumber, RecaptchaVerifier, signInWithEmailAndPassword } from 'firebase/auth';
import { useParams } from 'react-router-dom';
// import { analytics } from '../database';
// import { logEvent } from 'firebase/analytics';


export default function HomePage() {

    let { modus } = useParams();

    const minPasswordLength = 8;
    const maxPages = 6;

    const [errorMessage, setErrorMessage] = useState(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [recaptchaSolved, setRecaptchaSolved] = useState(false);
    const [smsHasBeenSent, setSmsHasBeenSent] = useState(false);
    const [userVerified, setUserVerified] = useState(false);

    const [termsPopupVisible, setTermsPopupVisible] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);

    const [uid, setUid] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [smsCode, setSmsCode] = useState("");

    const [gender, setGender] = useState(Gender.none);
    const [title, setTitle] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const [clientLocationName, setClientLocationName] = useState("");
    const [customSenderName, setCustomSenderName] = useState("");

    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [clientState, setClientState] = useState(""); // we mean the german "Bundesland" here

    const [pageNumber, setPageNumber] = useState(1);

    const [isInstallationRunning, setIsInstallationRunning] = useState(false);

    const [installationFailed, setInstallationFailed] = useState(false);

    const [userPreCheck, setUserPreCheck] = useState<UserPreCheckType | null>(null);


    useEffect(() => {
        setTermsPopupVisible(false);
        setSmsHasBeenSent(false);
        setUserVerified(false);
        setPageNumber(1);
        setInstallationFailed(false);
        setIsInstallationRunning(false);

        //logEvent(analytics, "page_view", {page_title: "Installation Wizard"});

    }, []);

    useEffect(() => {

        setSmsHasBeenSent(false);

        // Turn off phone auth app verification for testing
        getAuth().settings.appVerificationDisabledForTesting = false;

        if (pageNumber === 1) {
            // init recaptcha verifier
            (window as any).recaptchaVerifier = new RecaptchaVerifier("recaptcha-container-installation", {
                'size': 'normal',
                'callback': function (response) {
                    // reCAPTCHA solved, allow register.
                    setRecaptchaSolved(true);
                },
                'expired-callback': function () {
                    setRecaptchaSolved(false);
                }
            }, getAuth());

            (window as any).recaptchaVerifier.render().then(function (widgetId) {
                (window as any).recaptchaWidgetId = widgetId;
            });
        }

        if (pageNumber === 5) {
            startInstallation();
        }

    }, [pageNumber]);


    function gotoPreviousPage() {
        if (pageNumber <= 1) {
            window.history.back();
        }
        if (pageNumber > 1) setPageNumber(pageNumber - 1);
    }
    function gotoNextPage(e?) {
        if (e) e.preventDefault();

        if (pageNumber < maxPages) setPageNumber(pageNumber + 1);

        if (pageNumber === maxPages) {
            window.location.href = "https://cal.pickadoc.de";
        }
    }

    async function startInstallation() {
        setIsInstallationRunning(true);

        const instData = new InstallationModel();

        instData.userId = uid;
        instData.email = email;
        instData.mobileNumber = mobileNumber;

        instData.gender = gender;
        instData.title = title;
        instData.firstName = firstName;
        instData.lastName = lastName;

        instData.clientLocationName = clientLocationName;
        instData.customSenderName = customSenderName;

        instData.street = street;
        instData.city = city;
        instData.postalCode = postalCode;
        instData.state = clientState;

        let _modus = "normal" as "tradeshow" | "normal" | "basic" | "premium";

        switch (modus) {
            case "medicadeal":
                _modus = "tradeshow";
                break;

            case "basic":
                _modus = "basic";
                break;

            case "premium":
                _modus = "premium";
                break;

            default:
                _modus = "normal";
                break;
        }
        instData.modus = _modus;

        const installationSucceeded = await InstallationsService.startWizardInstallation(instData);

        if (installationSucceeded) {
            setIsInstallationRunning(false);

            setPageNumber(6);

            startRedirectTimer();

        } else {
            setInstallationFailed(true);
        }
    }

    function startRedirectTimer() {
        let countdown = 5;
        const intervalId = setInterval(() => {
            countdown -= 1;
            (document.getElementById("countdown") as any).innerText = countdown.toString();
            if (countdown <= 0) {
                clearInterval(intervalId);
                window.location.href = "https://cal.pickadoc.de/logout";
            }
        }, 1000);
        return () => clearInterval(intervalId);
    }

    function isPageOneFilled() {

        let emailOkay = email && email.length > 5;

        let passwordOkay = password && password.length > 5;

        let mobilePhoneOkay = mobileNumber && mobileNumber.length > 8;

        return emailOkay && passwordOkay && mobilePhoneOkay && recaptchaSolved && acceptTerms;
    }

    function isPreviousButtonEnabled() {
        if (isLoading || isInstallationRunning) return false;

        //if (pageNumber <= 1) return false;

        if (userVerified && pageNumber <= 3) return false;

        if (pageNumber >= 5) return false;

        return true;
    }

    function isNextButtonEnabled() {

        if (pageNumber === 5) return false;

        if (isLoading || isInstallationRunning) return false;

        return isPageOneFilled();
    }

    function LoadingMessage() {
        if (isLoading) {
            return <div>
                <i className='fad fa-spinner fa-spin'></i>
            </div>
        }

        return <></>
    }

    function NavigationButtons() {
        return <div className='kt-button-wrapper'>
            <button className='kt-btn' onClick={() => gotoPreviousPage()} disabled={!isPreviousButtonEnabled()}>&lt; Zurück</button>
            <button name="submit" type="submit" className='kt-btn' disabled={!isNextButtonEnabled()}>WEITER &gt;</button>
        </div>
    }

    function ErrorMessage() {
        if (errorMessage) {
            return <div className='kt-error-message'>{errorMessage}</div>
        }

        return <></>
    }

    async function registerNewUser(e) {

        e?.preventDefault();

        setErrorMessage(<></>);

        if (!validatePasswords()) return;

        let hasError = false

        setIsLoading(true);

        const trimmedMobilePhone = trimPhoneNumber(mobileNumber);

        try {

            // first check if this email or phone number is already used
            const _userPreCheck = await UsersService.doesUserExists(email, trimmedMobilePhone);

            setUserPreCheck(_userPreCheck);

            if (!_userPreCheck) {
                setIsLoading(false);
                setErrorMessage(<>Es ist etwas schief gelaufen. Bitte versuchen Sie es erneut.</>);
                return;
            }


            if (_userPreCheck.userHasLocationId) {
                setErrorMessage(<span>Sie Sind bereits bei uns registriert. Bitte loggen Sie Sich ein unter <a href="https://cal.pickadoc.de">https://cal.pickadoc.de</a></span>);
                hasError = true;
                setIsLoading(false);


            } else if (!_userPreCheck.isSuperUser && _userPreCheck.phoneUserExists && !_userPreCheck.phoneBelongsToEmail) {
                setErrorMessage(<span>Diese Email und Handynummer Kombination ist nicht zulässig. Bitte benutzen Sie eine andere Handynummer. Wir helfen Ihnen gerne weiter unter <a href="mailto:info@pickadoc.de">info@pickadoc.de</a></span>);
                hasError = true;
                setIsLoading(false);

            } else if (_userPreCheck?.emailUserExists && !_userPreCheck.phoneUserExists) {

                // try to login email user
                const result = await signInWithEmailAndPassword(getAuth(), email, password);

                if (result && result.user && result.user.uid) {
                    setUid(result.user.uid);

                    // link the email auth account with the phone number auth account, otherwise firebase creates two different accounts
                    if (!_userPreCheck.isSuperUser) {
                        const confirmationResult = await linkWithPhoneNumber(result.user, trimmedMobilePhone, (window as any).recaptchaVerifier);

                        (window as any).confirmationResult = confirmationResult;

                        getAuth().signOut();
                    }

                    setSmsHasBeenSent(true);
                }

                setIsLoading(false);
                setErrorMessage(<></>);

            } else {


                // create an email user
                const result = await createUserWithEmailAndPassword(getAuth(), email, password);

                if (!result || !result.user || !result.user.uid) throw new Error("Es ist etwas schief gelaufen, bitte versuchen Sie es später noch einmal.");

                setUid(result.user.uid);

                // link the email auth account with the phone number auth account, otherwise firebase creates two different accounts
                if (!_userPreCheck.isSuperUser) {
                    const confirmationResult = await linkWithPhoneNumber(result.user, trimmedMobilePhone, (window as any).recaptchaVerifier);

                    (window as any).confirmationResult = confirmationResult;

                    getAuth().signOut();
                }

                setSmsHasBeenSent(true);

                setIsLoading(false);

                setErrorMessage(<></>);

            }


        } catch (error) {

            getAuth().signOut();

            const errorCode = (error as any).code;

            hasError = true;

            switch (errorCode) {
                case "auth/email-already-in-use":
                case "auth/email-already-exists":
                    setErrorMessage(<span>Diese Email Adresse ist bereits registriert! Bitte benutzen Sie eine andere Email Adresse.</span>);
                    break;

                case "auth/invalid-email":
                    setErrorMessage(<span>Bitte geben Sie eine gültige Email Adresse an!</span>);
                    break;

                case "auth/invalid-password":
                case "auth/weak-password":
                    setErrorMessage(<span>Ihr Passwort ist zu schwach! Es muss mindestens 6 Zeichen lang sein.</span>);
                    break;

                case "auth/wrong-password":
                    setErrorMessage(<span>Diese Email Adresse ist bereits bei uns registriert,  aber das Passwort ist falsch! Bitte versuchen Sie es erneut.</span>);
                    break;

                case "auth/invalid-phone-number":
                    setErrorMessage(<span>Bitte geben Sie eine gültige Handynummer an!</span>);
                    break;

                default:
                    setErrorMessage(<span>{(error as any).message}</span>);
                    break;
            }
        }

        setIsLoading(false);

        if (!hasError) gotoNextPage(e);
    }

    async function verifyNewUserBySmsCode(e) {

        e?.preventDefault();

        setIsLoading(true);

        try {
            // now verify the SMS code
            if (!userPreCheck) {
                setIsLoading(false);
                setErrorMessage(<>Es ist etwas schief gelaufen. Bitte versuchen Sie es erneut.</>);
                return;
            }

            if (!userPreCheck.isSuperUser) {
                await (window as any).confirmationResult.confirm(smsCode);

                // a confirm does not sign in the user again, so we do it here manually
                const signInResult = await signInWithEmailAndPassword(getAuth(), email, password);

                if (signInResult && signInResult.user) {
                    setUid(signInResult.user.uid);
                }

            }

            setUserVerified(true);

            setIsLoading(false);

            gotoNextPage();

        } catch (error) {

            console.log("could not verify code: " + error);

            switch ((error as any).code) {
                case "auth/account-exists-with-different-credential":
                case "auth/credential-already-in-use":
                    // phone number already registered
                    setErrorMessage(<span>Diese Handynummer ist schon mit einem Konto verknüpft. Bitte loggen Sie Sich ein oder benutzen Sie eine andere Handynummer.</span>);
                    break;

                default:
                    // User couldn't sign in (bad verification code?)
                    setErrorMessage(<span>Wir konnten Sie mit diesem Code nicht verifizieren. Bitte versuchen Sie es erneut.</span>);
                    break;
            }

            setIsLoading(false);

        }

    }

    function FirstPage() {

        return <form onSubmit={registerNewUser}>

            <div className='kt-wizard-page'>

                <p>
                    <strong>Sind Sie Arzt oder Ärztin?</strong>
                    <br />
                    Gewinnen Sie internationale Patienten mit KI-Videos in ihrer Sprache per deutscher Texteingabe. Testen Sie Pickadoc.
                    <br />
                </p>

                <div className="kt-form-ctrl">
                    <label htmlFor='email'>Email: </label>
                    <input name="email" type="email" value={email} onChange={e => setEmail(e.currentTarget.value)} required></input>
                </div>

                <div className="kt-form-ctrl">
                    <label htmlFor='password'>Passwort: </label>
                    <input name="password" type="password" value={password} onChange={e => setPassword(e.currentTarget.value)} onBlur={validatePasswords} required minLength={minPasswordLength}></input>
                </div>

                <div className="kt-form-ctrl">
                    <label htmlFor='password2'>Passwort wiederholen: </label>
                    <input name="password2" type="password" value={password2} onChange={e => setPassword2(e.currentTarget.value)} onBlur={validatePasswords} required minLength={minPasswordLength}></input>
                </div>

                <div className="kt-form-ctrl">
                    <label htmlFor='mobilePhone'>Handynummer: </label>
                    <input name="mobilePhone" type="tel" value={mobileNumber} onChange={e => setMobileNumber(e.currentTarget.value)} required></input>
                </div>

                <div className="kt-center kt-margin">
                    <div>Wir senden Ihnen einen <strong>Aktivierungs-Code per SMS</strong> zu.</div><br />
                    Ich erkläre mich mit den <span className="kt-link kt-underline" onClick={() => setTermsPopupVisible(true)}>AGB</span> von Pick-A-Doc einverstanden.&nbsp;
                    <input name="acceptTerms" type="checkbox" required checked={acceptTerms} onChange={e => setAcceptTerms(e.target.checked)}></input>
                </div>

                <div id="recaptcha-container-installation" className="kt-center kt-margin"></div>

                <div className="kt-center kt-margin">
                    <div>Sind Sie bereits Kunde?</div><br />
                    Dann klicken Sie bitte hier: <a href="https://cal.pickadoc.de">Praxiskalender öffnen <i className="fas fa-external-link"></i></a>
                </div>

                <LoadingMessage />
                <ErrorMessage />


            </div>

            <NavigationButtons />

        </form>

    }

    function validatePasswords(): boolean {

        // do same password check only if user has entered something
        if (password.length < minPasswordLength || password2.length < minPasswordLength) {
            setErrorMessage(<></>);
            return true;
        } else if (password === password2) {
            setErrorMessage(<></>);
            return true;
        } else {
            setErrorMessage(<span>Die beiden Passwörter stimmen nicht überein!</span>);
            return false;
        }
    }

    return (
        <>
            <div></div>
            <div className="kt-installation-page">

                <div className='kt-registration-form'>
                    {/* <div className='kt-logo'><img src="images/logo-monochrome.svg" alt=""></img><span>Pick-A-Doc</span></div> */}

                    <div className='kt-logo-wrapper kt-mobile' style={{ marginTop: "10px" }}>
                        <img src='images/logo-monochrome.svg' alt='' width={50}></img>
                        <div>Pick-A-Doc</div>
                    </div>

                    {pageNumber < maxPages && <>
                        <h1>Registrieren</h1>
                        <h2>Schritt {pageNumber} von {maxPages}</h2>
                    </>}

                    {pageNumber === 1 && FirstPage()}

                    {pageNumber === 2 && <form onSubmit={verifyNewUserBySmsCode}>

                        <div className='kt-wizard-page'>

                            <p>Geben Sie bitte den Code ein, den Sie soeben per SMS erhalten haben.
                            </p>

                            <div className="kt-form-ctrl">
                                <label htmlFor='smsCode'>SMS Code: </label>
                                <input name="smsCode" type="text" value={smsCode} onChange={e => setSmsCode(e.currentTarget.value)} required></input>
                            </div>

                            <LoadingMessage />
                            <ErrorMessage />

                        </div>

                        <NavigationButtons />

                    </form>}


                    {pageNumber === 3 && <form onSubmit={gotoNextPage}>

                        <div className='kt-wizard-page'>

                            <div className="kt-form-ctrl">
                                <label htmlFor='gender'>Anrede: </label>
                                <select name="gender" value={gender} onChange={e => setGender(e.currentTarget.value as Gender)} required>
                                    <option value={""}>-- Bitte Auswahl treffen --</option>

                                    <option value={Gender.female}>Frau</option>
                                    <option value={Gender.male}>Herr</option>

                                </select>
                            </div>

                            <div className="kt-form-ctrl">
                                <label htmlFor='title'>Titel: </label>
                                <input name="title" type="text" value={title} onChange={e => setTitle(e.currentTarget.value)}></input>
                            </div>
                            <div className="kt-form-ctrl">
                                <label htmlFor='firstName'>Vorname: </label>
                                <input name="firstName" type="text" value={firstName} onChange={e => setFirstName(e.currentTarget.value)} required></input>
                            </div>
                            <div className="kt-form-ctrl">
                                <label htmlFor='lastName'>Nachname: </label>
                                <input name="lastName" type="text" value={lastName} onChange={e => setLastName(e.currentTarget.value)} required></input>
                            </div>

                        </div>

                        <NavigationButtons />

                    </form>}

                    {pageNumber === 4 && <form onSubmit={gotoNextPage}>

                        <div className='kt-wizard-page'>

                            <div className="kt-form-ctrl">
                                <label htmlFor='clientLocationName'>Praxisname: </label>
                                <input name="clientLocationName" type="text" value={clientLocationName} onChange={e => setClientLocationName(e.currentTarget.value)} required></input>
                            </div>

                            <div className="kt-form-ctrl">
                                <label htmlFor='customSenderName'>Ihr SMS Absendername: </label>
                                <input name="customSenderName" type="text" maxLength={11} pattern="[a-zA-Z0-9]+" value={customSenderName} onChange={e => setCustomSenderName(e.currentTarget.value)}></input>
                                <div>Keine Leer oder Sonderzeichen.</div>
                            </div>

                            <div className="kt-form-ctrl">
                                <label htmlFor='street'>Straße: </label>
                                <input name="street" type="text" value={street} onChange={e => setStreet(e.currentTarget.value)} required></input>
                            </div>

                            <div className="kt-form-ctrl">
                                <label htmlFor='postalCode'>Postleitzahl: </label>
                                <input name="postalCode" type="number" max="999999" value={postalCode} onChange={e => setPostalCode(e.currentTarget.value)} required></input>
                            </div>

                            <div className="kt-form-ctrl">
                                <label htmlFor='city'>Stadt: </label>
                                <input name="city" type="text" value={city} onChange={e => setCity(e.currentTarget.value)} required></input>
                            </div>

                            <div className="kt-form-ctrl">
                                <label htmlFor='state'>Bundesland: </label>
                                <select name="state" value={clientState} onChange={e => setClientState(e.currentTarget.value)} required>
                                    <option value={""}>-- Bitte Auswahl treffen --</option>

                                    <option value={"bw"}>Baden-Würtemberg</option>
                                    <option value={"by"}>Bayern</option>
                                    <option value={"be"}>Berlin</option>
                                    <option value={"bb"}>Brandenburg</option>
                                    <option value={"hb"}>Bremen</option>
                                    <option value={"hh"}>Hamburg</option>
                                    <option value={"he"}>Hessen</option>
                                    <option value={"mv"}>Mecklenburg-Vorpommern</option>
                                    <option value={"ni"}>Niedersachsen</option>
                                    <option value={"nw"}>Nordrhein-Westfalen</option>
                                    <option value={"rp"}>Rheinland-Pfalz</option>
                                    <option value={"sl"}>Saarland</option>
                                    <option value={"sn"}>Sachsen</option>
                                    <option value={"st"}>Sachsen-Anhalt</option>
                                    <option value={"sh"}>Schleswig-Holstein</option>
                                    <option value={"th"}>Thüringen</option>
                                </select>
                            </div>


                        </div>

                        <NavigationButtons />

                    </form>}


                    {pageNumber === 5 && <form onSubmit={gotoNextPage}>

                        {installationFailed ?
                            <div className='kt-wizard-page'>

                                <p>Es ist etwas schief gelaufen, bitte rufen Sie uns an, damit wir Ihnen bei der Installation weiterhelfen können.
                                </p>

                            </div>
                            :

                            <div className='kt-wizard-page'>

                                <p>Features und Pakete werden eingerichtet
                                </p>

                                <>
                                    <i className='fad fa-spinner fa-spin'></i>
                                    <br />
                                </>

                            </div>
                        }

                        <NavigationButtons />

                    </form>}


                    {pageNumber === 6 && <form onSubmit={gotoNextPage}>

                        <div className='kt-wizard-page'>

                            <p>
                                <h1>Einrichtung fertig</h1>
                                <br />
                                Sie erreichen Ihren Kalender unter
                            </p>

                            <a href="https://cal.pickadoc.de/logout" style={{ fontSize: "22px" }}>https://cal.pickadoc.de</a>

                            <p style={{ marginBottom: "50px" }}>Sie werden in <span id="countdown">{5}</span> Sekunden weitergeleitet.</p>

                        </div>

                        <NavigationButtons />

                    </form>}
                </div>


                <div className='kt-registration-bg'>
                    <div className='kt-logo-wrapper'>
                        <img src='images/logo-monochrome.svg' alt='' width={150}></img>
                        <div>Pick-A-Doc</div>
                        <div className='kt-sub-headline'>
                            <h1 style={{ margin: "60px 0 20px 0", lineHeight: "25px", textAlign: "center" }}>
                                Termin-Manager<br />
                                <span style={{ fontStyle: "italic" }}><img src="images/plus-icon-23581.png" width={20} alt="" />plus</span><br />
                                digitale Dokumente
                            </h1>
                        </div>
                    </div>
                    <div className='kt-footer'>
                        <span>Copyright © 2024 Pickadoc GmbH</span>
                    </div>
                </div>



            </div>

            <Popup visible={termsPopupVisible} onClosePopup={() => setTermsPopupVisible(false)}>
                <TermsPanel />
            </Popup>
        </>
    );
}