import Lead from "../models/lead";
import { getFunctions, httpsCallable } from "firebase/functions";
import firebaseApp from "../components/database";

const functions = getFunctions(firebaseApp, 'europe-west3');


const LeadsService = {

    async addLead(newLead: Lead): Promise<boolean> {

        try {

            const addLeadFunc = httpsCallable(functions, "addLead");
            
            await addLeadFunc(newLead);

            return true;

        } catch (error) {
            console.error("Error adding lead data: ", error);
        }

        return false;
    }
}

export default LeadsService;