import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import firebaseApp from "./../components/database";
import { getDownloadURL, getStorage, ref} from "firebase/storage";

const db = getFirestore(firebaseApp);

const ClientsService = {

    async getClient(clientId: string): Promise<any | null> {

        try {
            const document = await getDoc(doc(db, "clients", clientId));
                
            if (document.exists()) {
                let client:any = document.data();
                client.id = clientId;

                return client;

            } else {
                console.log("getClient: No such document: " + clientId);
            }

        } catch(error) {
            console.log("Error getting client: ", error);
        }

    },

    async updateClient(client: any): Promise<boolean> {

        try {
            const docRef = doc(db, "clients", client.id);
            await setDoc(docRef, client, { merge: true })
            
            return true;

        } catch(error) {
            console.log("Error updating client: ", error);
            return false;
        }

    },

    async getClientAvatarUrl(clientId: string, fallbackUrl: string = ""): Promise<string> {

        try {
            const storage = getStorage();

            const storageRef = ref(storage, `clients/${clientId}/client.jpg`);

            const url = await getDownloadURL(storageRef);

            return url ?? fallbackUrl;

        } catch (error) {
            console.log("Error getting client avatar url: ", error);
            return fallbackUrl;
        }

    },

    async getClientLogoUrl(clientId: string, fallbackUrl: string = ""): Promise<string> {

        try {
            const storage = getStorage();

            const storageRef = ref(storage, `clients/${clientId}/logo.png`);

            const url = await getDownloadURL(storageRef);

            return url ?? fallbackUrl;

        } catch (error) {
            console.log("Error getting client logo url: ", error);
            return fallbackUrl;
        }

    }

}

export default ClientsService;