import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { LogItemType } from "../models/logItem";
import firebaseApp from "./../components/database";
import LoggingService from "./loggingService";

const db = getFirestore(firebaseApp);

const RatingsService = {

    async getRating(ratingId): Promise<any | null> {

        try{

            const document = await getDoc(doc(db, "ratings", ratingId));
            
            if (document.exists()) {
                const rating = document.data();
                rating!.id = ratingId;

                return rating;

            } else {
                console.log("getRating: No such document: " + ratingId);
                return null;
            }

        } catch(error) {
            console.log("Error getting rating: ", error);
            return null;
        }
    },

    async setRating(rating): Promise<boolean> {
        if(rating){
            try {
                const docRef = doc(db, "ratings", rating.id);
                await setDoc(docRef, rating, {merge: true});
                
                const logMessage = `Patient ${rating.patientLastName} hat ${rating.doctorName} mit ${rating.rating} ${rating.rating === 1 ? "Stern" : "Sternen"} bewertet.`;
                LoggingService.log(LogItemType.doctorHasBeenRated, rating.appointmentId, rating.patientId, rating.doctorId, logMessage, rating.clientId, rating.locationId);
                return true;

            } catch(error) {
                console.error("Error setting rating: ", error);
            }
        }

        return false;
    }

}

export default RatingsService;