import React, { useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import ClonrService from '../../../services/clonrService';
import FlagsSelectCtrl from '../../flagsSelectCtrl';

const steps = ['Videoklon', 'Email', 'Fertig'];

export default function ClonrPlaygroundPage() {

    const [activeStep, setActiveStep] = useState(0);

    const [selectedLanguage, setSelectedLanguage] = useState("");
    const [selectedAvatar, setSelectedAvatar] = useState(1);

    const [videoText, setVideoText] = useState("");
    // const [videoText, setVideoText] = useState("Herzlich willkommen in der Zukunft! Mein Name ist Dr. Silke Fischer und ich bin seit elf Jahren praktizierende Ärztin in Düsseldorf.");
    const [email, setEmail] = useState("");

    const [videoTextError, setVideoTextError] = useState("");
    const [emailError, setEmailError] = useState("");

    useEffect(() => {

        if (videoText.length < 20) {
            setVideoTextError("Bitte geben Sie mindestens 20 Zeichen ein.")
        } else {
            setVideoTextError("");
        }

    }, [videoText]);

    useEffect(() => {

        if (email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            setEmailError("");
        } else {
            setEmailError("Bitte geben Sie ein gültige Emailadresse ein.");
        }

    }, [email]);


    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const resetAll = () => {
        setActiveStep(0);

        setEmail("");
        setVideoText("");

        setEmailError("");
        setVideoTextError("");
    }

    const handleGenerateVideo = async () => {
        const avatarId = `avatar${selectedAvatar}`;
        ClonrService.generatePlaygroundVideo(avatarId, videoText, selectedLanguage, email);

        setActiveStep(2);
    };


    const handleOnClickVideoClone = (vCloneId: number) => {

        setSelectedAvatar(vCloneId);

        for (let i = 1; i <= 3; i++) {
            const vidElement = document.getElementById("vclone" + i) as any;
            if (vidElement) {

                if (vidElement.id === "vclone" + vCloneId) {
                    if (vidElement.currentTime > 0 && !vidElement.paused && !vidElement.ended && vidElement.readyState > 2) {
                        vidElement.pause();
                        vidElement.currentTime = 0;
                    } else {
                        vidElement.play();
                    }

                } else {
                    vidElement.pause();
                    vidElement.currentTime = 0;
                }

            }
        }
    }

    return (

        <div className="kt-playgroundPage kt-clonr-playgroundPage">

            <Dialog
                open={true}
                maxWidth="md"
                fullWidth
                className='kt-playgroundPage-dialog'
                sx={{
                    "& .MuiDialog-container": {
                        alignItems: "flex-start",
                        ".MuiPaper-root": {borderRadius: "20px !important"}
                    }
                }}
            >
                <DialogTitle><img src="/images/logo.svg" width={25} alt="logo" /> ClonR Playground</DialogTitle>

                <DialogContent>
                    <Box sx={{ width: '100%' }}>
                        <Stepper nonLinear activeStep={activeStep}>
                            {steps.map((label, index) => (
                                <Step key={label} completed={activeStep > index}>
                                    <StepButton color="inherit">
                                        {label}
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>

                        <Box mt={2} style={{ minHeight: "400px" }}>
                            {activeStep === 0 &&
                                <React.Fragment>

                                    <Typography sx={{ mt: 4 }}>
                                        Legen Sie sofort los und erstellen Sie ein personalisiertes Video in vielen verschiedenen Sprachen.
                                    </Typography>

                                    <Typography sx={{ mt: 4 }}>
                                        Wählen Sie zuerst einen der drei Videoklone:
                                    </Typography>

                                    <Box sx={{ flexGrow: 1 }} mt={1}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={4} textAlign="center" style={{ position: "relative" }}>
                                                <video preload='metadata' playsInline id="vclone1" className={selectedAvatar === 1 ? "kt-avatar-image kt-selected" : "kt-avatar-image"} src="../images/playgrounds/drsilkefischr_200_1.mp4#t=0.1" onClick={e => handleOnClickVideoClone(1)} />
                                                {selectedAvatar !== 1 && <div className='kt-play-button'><i className='far fa-play-circle'></i></div>}
                                                <div className='kt-img-label'>Dr. Silke Fischer</div>
                                            </Grid>
                                            <Grid item xs={12} md={4} textAlign="center" style={{ position: "relative" }}>
                                                <video preload='metadata' playsInline id="vclone2" className={selectedAvatar === 2 ? "kt-avatar-image kt-selected" : "kt-avatar-image"} src="../images/playgrounds/drmarkusschmidt_200_1.mp4#t=0.1" onClick={e => handleOnClickVideoClone(2)} />
                                                {selectedAvatar !== 2 && <div className='kt-play-button'><i className='far fa-play-circle'></i></div>}
                                                <div className='kt-img-label'>Dr. Markus Schmidt</div>
                                            </Grid>
                                            <Grid item xs={12} md={4} textAlign="center" style={{ position: "relative" }}>
                                                <video preload='metadata' playsInline id="vclone3" className={selectedAvatar === 3 ? "kt-avatar-image kt-selected" : "kt-avatar-image"} src="../images/playgrounds/drstefanmeir_200_1.mp4#t=0.1" onClick={e => handleOnClickVideoClone(3)} />
                                                {selectedAvatar !== 3 && <div className='kt-play-button'><i className='far fa-play-circle'></i></div>}
                                                <div className='kt-img-label'>Dr. Stefan Meir</div>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Grid item xs={12} mt={4}>
                                        <TextField
                                            label="Geben Sie hier etwas Text ein, den der Video-Avatar für Sie sprechen soll."
                                            variant='outlined'
                                            required type="text"
                                            multiline
                                            rows={4}
                                            fullWidth
                                            value={videoText}
                                            onChange={e => setVideoText(e.currentTarget.value)}
                                            inputProps={{ minLength: 5, maxLength: 200 }}
                                            error={videoTextError !== ""}
                                            helperText={videoTextError}
                                        />
                                    </Grid>

                                    <Box mt={4}>
                                        <FlagsSelectCtrl
                                            selectedLanguage={selectedLanguage}
                                            onSelectionChange={setSelectedLanguage}
                                            label={"Übersetzen nach"}
                                        />
                                    </Box>

                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>

                                        <Box sx={{ flex: '1 1 auto' }} />

                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button onClick={handleNext} sx={{ mr: 1 }} variant='contained'
                                            disabled={videoText === "" || selectedAvatar === 0 || videoTextError !== "" || selectedLanguage === ""}
                                        >
                                            Weiter
                                        </Button>
                                    </Box>
                                </React.Fragment>
                            }

                            {activeStep === 1 &&
                                <React.Fragment>

                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Ihre Email an die wir das fertige Video senden können."
                                                variant="outlined"
                                                required
                                                type='email'
                                                fullWidth
                                                value={email}
                                                onChange={e => setEmail(e.currentTarget.value)}
                                                error={emailError !== ""}
                                                helperText={emailError}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Button
                                            color="inherit"
                                            onClick={handleBack}
                                            sx={{ mr: 1 }}
                                        >
                                            Zurück
                                        </Button>
                                        <Button onClick={handleGenerateVideo} sx={{ mr: 1 }} variant='contained'
                                            disabled={email === "" || emailError !== ""}
                                        >
                                            Video erstellen
                                        </Button>
                                    </Box>
                                </React.Fragment>
                            }

                            {activeStep === 2 &&
                                <React.Fragment>

                                    <Box textAlign={"center"} pt={3}>

                                        <Typography color={"grey"}>
                                            <i className="far fa-mailbox" style={{ fontSize: "120px" }}></i>
                                        </Typography>
                                        <br />
                                        <Typography>
                                            Das war's schon. Ihr AI Video wird nun generiert und ist in einigen Minuten fertig.
                                        </Typography>
                                        <br />
                                        <Typography>
                                            Sobald Ihr Video erstellt ist, bekommen Sie eine Email an {email} mit dem Link zu Ihrem Video.
                                        </Typography>
                                    </Box>

                                </React.Fragment>
                            }

                        </Box>

                    </Box>

                    <Typography sx={{ mt: 2, textAlign: "center" }}>
                        © 2024 Pickadoc GmbH
                    </Typography>

                </DialogContent>
            </Dialog>

        </div >
    );
}