import React, {useEffect} from 'react';

interface Props {
  visible: boolean,
  children: any,
  onClosePopup: () => void
}


const Popup: React.FC<Props> = ({ visible, children, onClosePopup}) => {

  useEffect(() => {
   
    if(visible){
      document.body.classList.add("kt-fixed");
    } else {
      document.body.classList.remove("kt-fixed");
    }

  });

  return (
    visible ? 
    <div className="kt-popup">
      
      <div className="kt-overlay"></div>

      <div className="kt-panel">
        <div className="kt-panel-head"><i className="fal fa-times-circle" onClick={onClosePopup}></i></div>
        <div className="kt-panel-content">
            {children}
        </div>
        <div className="kt-panel-footer">
          <div className="kt-btn" onClick={onClosePopup}>SCHLIEßEN</div>
        </div>
      </div>

    </div>
    : <div></div>
  );
}

export default Popup;