import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";

import firebaseApp from "./database";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import PatientsService from "../services/patientsService";

import AccountVerificationPanel from "./accountVerificationPanel";

const LoginUserPanel: React.FC = () => {

    const { appointmentId } = useParams();

    const navigate = useNavigate();

    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [mobilePhoneNumber, setMobilePhoneNumber] = useState("");

    const [isAccountVerificationPanelVisible, setIsAccountVerificationPanelVisible] = useState(false);

    useEffect(() => {
        setIsAccountVerificationPanelVisible(false);
    }, []);

    async function onLoginFormSubmit(event) {
        event.preventDefault();

        try {
            const auth = getAuth();
            const result = await signInWithEmailAndPassword(auth, loginEmail, loginPassword);

            if (!result.user?.phoneNumber) {
                // user has no phone verification
                // we need to verify his mobile phone number 

                if (!result.user?.uid) {
                    console.error(`User not found in DB for email: ${loginEmail}`);
                    return;
                }

                PatientsService.getPatient(result.user.uid)
                    .then((patient) => {
                        setMobilePhoneNumber(patient.mobilePhoneNumber);
                        setIsAccountVerificationPanelVisible(true);
                    })
                    .catch(() => {
                        alert("Dies ist kein gültiger Patienten Account. Bitte benutzen Sie eine andere Email Adresse.");
                    })

            } else {
                // user has a phone verification
                // logged in, now goto patient appointments page or confirmation page

                if (appointmentId) {
                    navigate(`/confirmation/${appointmentId}`);
                } else {
                    navigate(`/appointments`);
                }

            }


        } catch (error: any) {
            var errorCode = error.code;

            switch (errorCode) {
                case "auth/invalid-email":
                case "auth/user-not-found":
                case "auth/wrong-password":
                    alert("Die von Ihnen eingegebene E-Mail-Adresse oder das Passwort ist falsch. Bitte versuchen Sie es erneut");
                    break;

                case "auth/user-disabled":
                    alert("Dieser Account ist gesperrt, bitte wenden Sie Sich telefonisch an uns.");
                    break;

                default:
                    alert(error.message);
                    break;
            }

        }
    }

    if (isAccountVerificationPanelVisible) {
        return (
            <AccountVerificationPanel
                _smsHasBeenSent={false}
                _email={loginEmail}
                _password={loginPassword}
                _mobilePhoneNumber={mobilePhoneNumber}
            />
        );
    } else {
        return (
            <div className="kt-panel">
                <div className="kt-panel-content">
                    <div className="kt-center">Sie haben bereits ein Pick-A-Doc Konto?</div>

                    <form name="login" onSubmit={onLoginFormSubmit}>
                        <input type="email" placeholder="E-Mail-Adresse" required value={loginEmail} onChange={e => setLoginEmail(e.target.value)} />
                        <input type="password" placeholder="Passwort" required value={loginPassword} onChange={e => setLoginPassword(e.target.value)} />
                        <Link to={"/password-recovery"} className="kt-align-right">Passwort vergessen?</Link>
                        <input className="kt-btn-big kt-btn-secondary-color" type="submit" value="ANMELDEN" />
                    </form>

                    <div className="kt-history-back-link" onClick={e => window.history.back()}><i className="far fa-angle-left"></i> Zurück</div>
                </div>
            </div>
        )
    }

}

export default LoginUserPanel;