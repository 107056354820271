import { getDate } from "../utils";

export enum LeadSourceType {
    form,
    clonrPlayground,
    clonrPlaygroundForm,
    clonrVoicePlayground,
    clonrVoicePlaygroundForm,
    landingPagePlayground
}


export class Lead {

    id: string = "";

    source: LeadSourceType = LeadSourceType.form;

    firstName: string = "";
    lastName: string = "";

    mobilePhone: string = "";
    email: string = "";

    message: string = "";

    playgroundLink: string = ""; // this is the link the landing page which the user gets per email

    createdAt: Date = new Date();

    clone(): Lead {

        const l = new Lead();
        l.fromObject(this.toJSON(true));

        return l;
    }

    toJSON(useDate: boolean): object {

        const obj: any = {};


        obj.id = this.id;

        obj.source = this.source;

        obj.firstName = this.firstName;
        obj.lastName = this.lastName;

        obj.mobilePhone = this.mobilePhone;
        obj.email = this.email;

        obj.playgroundLink = this.playgroundLink;

        obj.message = this.message;

        if (useDate) {
            obj.createdAt = this.createdAt;
        } else {
            obj.createdAt = this.createdAt.toJSON();
        }


        return obj;
    }

    fromObject(o: any) {

        if (o.id) {
            this.id = o.id;
        }

        this.firstName = o.firstName ?? "";
        this.lastName = o.lastName ?? "";

        this.mobilePhone = o.mobilePhone ?? "";
        this.email = o.email ?? "";
        
        this.source = o.source ?? LeadSourceType.form;

        this.playgroundLink = o.playgroundLink ?? "";

        this.message = o.message ?? "";

        this.createdAt = getDate(o.createdAt) ?? new Date();
    }

    getLeadSourceDisplayName(): string {
        switch (this.source) {
            case LeadSourceType.form:
                return "Formular";

            case LeadSourceType.clonrPlayground:
                return "ClonR Playground";

            case LeadSourceType.clonrPlaygroundForm:
                return "ClonR Playground Formular";

            case LeadSourceType.clonrVoicePlayground:
                return "ClonR Voice Playground";

            case LeadSourceType.clonrVoicePlaygroundForm:
                return "ClonR Voice Playground Formular";

            case LeadSourceType.landingPagePlayground:
                return "Landing Page Playground";


            default:
                return "";
        }
    }

}


export default Lead;