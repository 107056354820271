import { getFunctions, httpsCallable } from "firebase/functions";
import firebaseApp from "./../components/database";
import { getFirestore, doc, getDoc } from "firebase/firestore";

const functions = getFunctions(firebaseApp, 'europe-west3');

const db = getFirestore(firebaseApp);

const CampaignsService = {

    async getCampaign(campaignId: string, clientId: string, locationId: string): Promise<any | null> {

        try {
            const docRef = doc(db, "clients", clientId, "locations", locationId, "campaigns", campaignId);
            const document = await getDoc(docRef);

            if (document.exists()) {

                let campaign: any = null;

                campaign = document.data();
                campaign.id = campaignId;

                return campaign;

            } else {
                console.log("getCampaign: No such document: " + campaignId);
            }

        } catch (error) {
            console.log("Error getting campaign: ", error);
        }

        return null;
    },

    async updateCampaignPatientProperties(campaignPatientProperties: any, campaignId: string, clientId: string, locationId: string): Promise<void> {

        try {
            const data = {
                campaignPatientProperties: campaignPatientProperties,
                campaignId: campaignId,
                clientId: clientId,
                locationId: locationId
            }

            const updateCampaignPatientFunc = httpsCallable(functions, "updateCampaignPatientProperties");
            await updateCampaignPatientFunc(data);

        } catch (error) {
            console.log("Error updating campaign patient properties: ", error);
        }

    },
}

export default CampaignsService;