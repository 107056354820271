import firebaseApp from "./../components/database";
import Prediction from "../models/prediction";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import ClonRVideo, { VideoGroup } from "../models/clonRVideo";
import LandingPageData from "../models/landingPageData";
import { getFunctions, httpsCallable } from "firebase/functions";
import { collection, doc, getDoc, getDocs, getFirestore, orderBy, query, where } from "firebase/firestore";

const functions = getFunctions(firebaseApp, 'europe-west3');

const db = getFirestore(firebaseApp);

const ClonrService = {

    async generatePlaygroundVideo(playgroundAvatarId: string, videoText: string, language: string, email: string): Promise<any | null> {

        try {

            const generatePlaygroundClonrVideoFunc = httpsCallable(functions, "generatePlaygroundClonrVideo");
            const result: any = await generatePlaygroundClonrVideoFunc(
                {
                    playgroundAvatarId: playgroundAvatarId,
                    text: videoText,
                    targetLanguage: language,
                    email: email
                });

            return result.data;

        } catch (error) {
            console.log("Error generating playground video: ", error);
            return "";
        }


    },

    async getPrediction(predictionId: string): Promise<Prediction | null> {

        try {

            if (!predictionId) {
                return null;
            }

            const document = await getDoc(doc(db, "predictions", predictionId));

            if (document.exists()) {
                const prediction = new Prediction();
                prediction.fromObject(predictionId, document.data());

                return prediction;

            } else {
                console.log("getPrediction: No such document: " + predictionId);
                return null;
            }

        } catch (error) {
            console.log("Error getting prediction: ", error);
            return null;
        }

    },

    async getVideoUrl(videoId: string): Promise<string> {

        try {
            const storage = getStorage();

            const storageRef = ref(storage, `clonr/output/${videoId}.mp4`);

            const url = await getDownloadURL(storageRef);

            return url;

        } catch (error) {
            return "";
        }

    },

    async getVidoesByGroupId(videoGroupId: string, clientId: string): Promise<VideoGroup | null> {

        const videos: ClonRVideo[] = [];

        try {
            const q = query(
                collection(db, "clients", clientId, "clonRVideos"),                
                where("isDeleted", "==", false),
                where("groupId", "==", videoGroupId)
            );

            const querySnapshot = await getDocs(q);

            querySnapshot.forEach(doc => {

                const video = new ClonRVideo();
                video.fromObject(doc.id, doc.data());

                videos.push(video);
            });

            if (videos.length > 0) {
                const group: VideoGroup = {
                    id: videos[0].getGroupId(),
                    name: videos[0].getGroupName(),
                    videos: videos,
                    latestVideoDate: videos[0].createdAt
                };

                return group;
            }

        } catch (error) {
            console.log("Error getting videos by group id: ", error);
        }

        return null;
    },

    async getVideoGroupForLandingPage(landingPageData: LandingPageData, calendarId: string, clientId: string): Promise<VideoGroup | null> {

        let videoGroupId = "";

        const videos: ClonRVideo[] = [];

        if (!landingPageData.videoGroups) return null;

        const calendarVideoGroup = landingPageData.videoGroups.find(g => g.calendarId === calendarId);

        if (calendarVideoGroup) {
            videoGroupId = calendarVideoGroup.videoGroupId;
            // } else if (landingPageData.videoGroupId) { // disabled for now
            //     videoGroupId = landingPageData.videoGroupId;
        } else {
            return null;
        }

        try {

            const q = query(
                collection(db, "clients", clientId, "clonRVideos"),                
                where("isDeleted", "==", false),
                where("groupId", "==", videoGroupId)
            );

            const querySnapshot = await getDocs(q);

            querySnapshot.forEach(doc => {

                const video = new ClonRVideo();
                video.fromObject(doc.id, doc.data());

                videos.push(video);
            });

            if (videos.length > 0) {
                const group: VideoGroup = {
                    id: videos[0].getGroupId(),
                    name: videos[0].getGroupName(),
                    videos: videos,
                    latestVideoDate: videos[0].createdAt
                };

                return group;
            }

        } catch (error) {
            console.log("Error getting videos for landing page: ", error);
        }

        return null;
    }

}

export default ClonrService;