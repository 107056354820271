import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ClonrService from '../../../services/clonrService';
import { isMobileDevice } from '../../../utils';
import LeadsService from '../../../services/leadsService';
import Lead, { LeadSourceType } from '../../../models/lead';

export default function ClonrPlaygroundLandingPage() {

    const [videoUrl, setVideoUrl] = useState("");
    const [generatedVideoUrl, setGeneratedVideoUrl] = useState("");
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [arePricesVisible, setArePricesVisible] = useState(false);
    const [formFilled, setFormFilled] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    let { videoId } = useParams();

    useEffect(() => {
        getVideoUrl();

        setFormFilled(false);
        setIsSaving(false);
    }, []);

    const getVideoUrl = async () => {
        if (videoId && videoUrl === "") {

            const _videoUrl = await ClonrService.getVideoUrl(videoId);

            if (_videoUrl) {
                setVideoUrl(_videoUrl);
                setGeneratedVideoUrl(_videoUrl);
            }
        }
    }

    const handleOnClickVideoClone = () => {
        const vidElement = document.getElementById("vclonePreview") as any;
        if (vidElement) {

            if (vidElement.currentTime > 0 && !vidElement.paused && !vidElement.ended && vidElement.readyState > 2) {
                vidElement.pause();
                setIsVideoPlaying(false);
            } else {
                vidElement.play();
                setIsVideoPlaying(true);
            }

        }

    }

    const handleOnVideoEnded = () => {

        const vidElement = document.getElementById("vclonePreview") as any;
        if (vidElement) {

            if (videoUrl.indexOf("clonrvid.mp4") === -1) {
                setVideoUrl("/videos/clonrvid.mp4");

                setTimeout(() => {
                    const vidElement = document.getElementById("vclonePreview") as any;
                    if (vidElement) {
                        vidElement.play();
                    }
                }, 300);

            } else {
                vidElement.currentTime = 0;
                setIsVideoPlaying(false);
                setVideoUrl(generatedVideoUrl);
            }

        }
    }

    const togglePrices = () => {
        setArePricesVisible(!arePricesVisible);
    }



    async function handleSubmit(event) {
        event.preventDefault();

        setIsSaving(true);

        const newLead = new Lead();
        newLead.firstName = (document.getElementById("firstName") as HTMLInputElement).value;
        newLead.lastName = (document.getElementById("lastName") as HTMLInputElement).value;
        newLead.mobilePhone = (document.getElementById("mobilePhone") as HTMLInputElement).value;
        newLead.source = LeadSourceType.clonrPlaygroundForm;
        newLead.playgroundLink = window.location.href;

        await LeadsService.addLead(newLead);

        setFormFilled(true);
        setIsSaving(false);
    }


    return (

        <div className="kt-playgroundPage kt-clonr-playgroundLandingPage">

            <Box className="kt-playground-container" maxWidth={"800px"} style={{ background: "rgba(255,255,255,0.9)", borderRadius: "10px" }}>
                <Box textAlign={'left'} mt={1} p={2}>
                    <a href="http://www.pickadoc.de/" target="_blank" rel="noreferrer">
                        <img src="https://pickadoc.de/images/logo-text-ai.png"
                            alt="Logo"
                            style={{ height: '40px', border: 'none', textDecoration: 'none', color: '#ffffff' }} />
                    </a>
                </Box>

                <Box textAlign={'center'} p={1}>
                    <Typography variant='h4'>
                        Ihr AI Video ist fertig!
                    </Typography>
                </Box>
                <Box textAlign={'center'} p={1}>
                    Die Zukunft der Videoerstellung beginnt hier. Nutzen Sie beim nächsten Mal Ihr Gesicht und Ihre eigene Stimme in allen Sprachen.
                </Box>
                <Box textAlign={'center'} p={1}>
                    <div className='kt-video-wrapper'>
                        <video
                            className='kt-video'
                            playsInline={!isMobileDevice()}
                            id="vclonePreview"
                            preload='metadata'
                            src={`${videoUrl}#t=0.1`}
                            onClick={handleOnClickVideoClone}
                            onEnded={handleOnVideoEnded}
                        />
                        <div className={isVideoPlaying ? 'kt-play-button kt-hide' : 'kt-play-button'}><i className='far fa-play-circle'></i></div>
                    </div>
                </Box>

                {!arePricesVisible && <Box textAlign={'center'} bgcolor={"white"} p={1} pb={3} borderBottom={"4px solid grey"}>
                    <p><strong>Jetzt Ihren eigenen Videoclone erstellen</strong></p>
                    <Button
                        variant='contained'
                        color="secondary"
                        onClick={togglePrices}
                    >ClonR starten</Button>
                </Box>}

                {isSaving &&
                    <Box p={1} textAlign={"center"}>
                        <h2><i className='fad fa-spinner fa-spin'></i> Formular wird verschickt...</h2>
                    </Box>
                }

                {(arePricesVisible && !isSaving) &&
                    (formFilled ?
                        <Box p={1} textAlign={"center"}>
                            <h2>Vielen Dank! Wir melden uns umgehend bei Ihnen.</h2>
                        </Box>
                        :
                        <Box p={1} textAlign={"center"}>

                            <h2>Hat Ihnen Pickadoc gefallen?</h2>
                            <h3>Wir rufen zurück!</h3>
                            <form onSubmit={handleSubmit}>

                                <div className='kt-form-ctrl' style={{ margin: "10px" }}>
                                    <TextField id="firstName" type='text' placeholder='Vorname' required style={{ marginLeft: 0 }}></TextField>
                                </div>
                                <div className='kt-form-ctrl' style={{ margin: "10px" }}>
                                    <TextField id="lastName" type='text' placeholder='Nachname' required style={{ marginLeft: 0 }}></TextField>
                                </div>

                                <div className='kt-form-ctrl' style={{ margin: "10px" }}>
                                    <TextField id="mobilePhone" type='tel' placeholder='Handynummer' required style={{ marginLeft: 0 }}></TextField>
                                </div>

                                <div className='kt-form-ctrl' style={{ margin: "10px" }}>
                                    <Button className='kt-btn' variant='contained' color='secondary' type='submit'>ABSENDEN</Button>
                                </div>

                            </form>
                        </Box>
                    )

                    // <Box className="kt-prices" p={1}>
                    //     <Grid container>
                    //         <Grid item xs={12} sm={6} textAlign={"center"} mt={3}>
                    //             <a href="https://pickadoc.de">
                    //                 <img src="/images/playgrounds/clonr-basic-price.png" alt="ClonR Basic" />
                    //             </a>
                    //         </Grid>
                    //         <Grid item xs={12} sm={6} textAlign={"center"}>
                    //             <a href="https://pickadoc.de">
                    //                 <img src="/images/playgrounds/clonr-pro-price.png" alt="ClonR Pro" />
                    //             </a>
                    //         </Grid>
                    //     </Grid>
                    // </Box>
                }

                <Box textAlign={'center'} bgcolor={"white"} p={1}>
                    <span style={{ fontSize: "25px" }}>Marketing mit ClonR</span>
                </Box>

                <Box textAlign={'center'} bgcolor={"white"} p={1} borderTop={"4px solid grey"} borderBottom={"4px solid grey"}>
                    <p style={{ fontSize: "18px" }}><strong>Erstellen Sie persönliche Videos für</strong></p>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={4}>Terminbestätigungen</Grid>
                        <Grid item xs={6} sm={4}>Terminerinnerungen</Grid>
                        <Grid item xs={6} sm={4}>Terminerklärungen</Grid>
                        <Grid item xs={6} sm={4}>OP-Aufklärungen</Grid>
                        <Grid item xs={6} sm={4}>Prä/Post OP Infos</Grid>
                        <Grid item xs={6} sm={4}>Genesungswünsche</Grid>
                        <Grid item xs={6} sm={4}>Jahres Recall</Grid>
                        <Grid item xs={6} sm={4}>Neupatienten-Akquise</Grid>
                        <Grid item xs={6} sm={4}>Marketing Instagram</Grid>
                    </Grid>
                    <p style={{ fontSize: "24px" }}><strong>allein durch Texteingabe</strong></p>
                </Box>

                <Box textAlign={'center'} bgcolor={"white"} p={1}>
                    <img src="/images/clonr.png" />
                </Box>


                <Box textAlign={'center'} mt={2} p={1}>
                    <a href="http://www.pickadoc.de/" target="_blank" rel="noreferrer">
                        <img src="https://pickadoc.de/images/logo.png" alt="Logo"
                            style={{ height: '30px', border: 'none', textDecoration: 'none', color: '#ffffff' }} /></a>

                    <p style={{ margin: 0, fontSize: '12px', lineHeight: '20px' }}>© 2024 Pickadoc
                        <br />
                        <br />
                        <a href="https://pickadoc.de/contact" target="_blank" rel="noreferrer">Kontakt</a>
                        &nbsp;|&nbsp;
                        <a href="https://pickadoc.de/terms" target="_blank" rel="noreferrer">AGB</a>
                        &nbsp;|&nbsp;
                        <a href="https://pickadoc.de/legal-notice" target="_blank" rel="noreferrer">Impressum</a>
                        &nbsp;|&nbsp;
                        <a href="https://pickadoc.de/data-protection" target="_blank" rel="noreferrer">Datenschutz</a>
                    </p>
                </Box>
            </Box>
        </div >
    );
}