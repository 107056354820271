import React, { useState } from 'react';
import VideoPlayerCtrl from './videoPlayerCtrl';
import ClonRVideo, { VideoGroup } from '../models/clonRVideo';
import CountryFlagCtrl from './countryFlagCtrl';


interface Props {
    id: string;
    videoGroup: VideoGroup
}


const VideoGroupPlayerCtrl: React.FC<Props> = ({ id, videoGroup }) => {

    const [selectedVideo, setSelectedVideo] = useState<ClonRVideo>(videoGroup.videos.length > 0 ? videoGroup.videos[0] : new ClonRVideo());

    const playVideo = () => {
        const vidElement = document.getElementById(id) as any;
        if (vidElement) {
            vidElement.play();
        }
    }

    const handleOnLanguageButtonClick = (video: ClonRVideo) => {
        setSelectedVideo(video);
        setTimeout(playVideo, 200);
    }

    return (
        <div className='kt-video-group-player-ctrl'>
            <VideoPlayerCtrl
                id={id}
                videoUrl={selectedVideo.videoUrl}
                thumbnailUrl={selectedVideo.thumbnailUrl}
            />

            {videoGroup.videos.filter(v => v.status === "finished").length > 1 &&
                <div className='kt-language-buttons'>
                    {videoGroup.videos.filter(v => v.status === "finished").map(v => v.id === selectedVideo.id ?
                        <span key={v.id} className='kt-language-button kt-large kt-selected' onClick={() => handleOnLanguageButtonClick(v)}>
                            <CountryFlagCtrl countryCode={v.language} />
                        </span>
                        :
                        <span key={v.id} className='kt-language-button kt-large' onClick={() => handleOnLanguageButtonClick(v)}>
                            <CountryFlagCtrl countryCode={v.language} />
                        </span>
                    )}
                </div>
            }

        </div >
    );
}

export default VideoGroupPlayerCtrl;