import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, query, setDoc, updateDoc, where } from "firebase/firestore";
import firebaseApp from "../components/database";
import { getFullUserName, getDate, trimPhoneNumberWithoutCountryCode } from "../utils";

import UsersService from "./usersService";
import { getFunctions, httpsCallable } from "firebase/functions";

const db = getFirestore(firebaseApp);
const functions = getFunctions(firebaseApp, 'europe-west3');

let geocoder: any = null;


const PatientsService = {

    async getPatient(patientId: string): Promise<any> {

        try {

            const document = await getDoc(doc(db, "patients", patientId));

            if (document.exists()) {
                const patient: any = document.data();
                patient.id = patientId;

                return patient;
            } else {
                console.log("getPatient: No such document: " + patientId);
                return null;
            }


        } catch (error) {
            console.log("Error getting patient: ", error);
            return null;
        }
    },


    getFullAdress(patient): string | null {
        if (patient && patient.city && patient.street && patient.postalCode) {
            return `${patient.street}, ${patient.postalCode} ${patient.city}`;
        }

        return null;
    },

    // async getGeoPoint(patient) {

    //     return new Promise((resolve, reject) => {

    //         try {
    //             if (!geocoder) {
    //                 geocoder = new (window as any).google.maps.Geocoder();
    //             }

    //             const address = this.getFullAdress(patient);

    //             if (address) {
    //                 geocoder.geocode({ 'address': address }, function (results, status) {
    //                     if (status === 'OK') {
    //                         resolve(results[0].geometry.location);
    //                     } else {
    //                         resolve(null);
    //                     }
    //                 });

    //             } else {
    //                 console.log("Error getting geo point");
    //                 resolve(null);
    //             }

    //         } catch (error) {
    //             console.log("Error getting geo point: ", error);
    //             resolve(null);
    //         }

    //     });
    // },

    async createPatient(patient) {

        patient.newPatient = true;

        try {

            // Get geoloaction from adress
            // const geoPoint = await PatientsService.getGeoPoint(patient);

            // if (geoPoint) {
            //     patient.location = new firebase.firestore.GeoPoint((geoPoint as any).lat(), (geoPoint as any).lng());
            // } else {
            //     patient.location = new firebase.firestore.GeoPoint(0, 0);
            // }

            // create a new patient
            const result = await setDoc(doc(db, "patients", patient.id), patient);

            return result;

        } catch (error) {
            console.log("Error creating new patient: ", error);
            return null;
        }

    },

    async updatePatient(patient): Promise<string | null> {

        try {
            if (patient.id) {
                // update existing patient
                const docRef = doc(db, "patients", patient.id);
                await setDoc(docRef, patient, { merge: true });

                return patient.id;

            } else {
                // create a new patient
                const docRef = await addDoc(collection(db, "patients"), patient);

                return docRef.id;
            }

        } catch (error) {
            console.error("Error updating patient: ", error);
            return null;
        }

    },

    async getClientLocationPatient(clientId: string, locationId: string, patientId: string) {

        try {
            const document = await getDoc(doc(db, "clients", clientId, "locations", locationId, "patients", patientId));

            let patient: any = null;

            if (document.exists()) {
                patient = document.data();
                patient.id = patientId;

                return patient;

            } else {
                console.log("getClientLocationPatient: No such document: " + patientId);
                return null;
            }

        } catch (error) {
            console.log("Error getting client location patient: ", error);
            return null;
        }

    },

    async getOrCreateClientLocationPatient(clientId: string, locationId: string, userPatient) {

        try {
            let userId = userPatient.id;

            if (userPatient.clientUserIds && userPatient.clientUserIds[`${clientId}-${locationId}`]) {
                userId = userPatient.clientUserIds[`${clientId}-${locationId}`];
            }

            const document = await getDoc(doc(db, "clients", clientId, "locations", locationId, "patients", userId));

            let patient: any = null;

            if (document.exists()) {
                patient = document.data();
                patient.id = userId;

                return patient;

            } else {

                // search now a client lcoation patient by mobile phone number, firstName and lastName
                const clPatient = await PatientsService.getClientLocationPatientByMobilePhoneNumberAndName(clientId, locationId, userPatient);

                if (clPatient) {
                    return clPatient;

                } else {

                    // no patient found in the client location
                    // so create a new patient
                    return PatientsService.createClientLocationPatient(clientId, locationId, userPatient);
                }
            }

        } catch (error) {
            console.log("Error in getOrCreateClientLocationPatient: ", error);
            return null;
        }

    },


    async createClientLocationPatient(clientId: string, locationId: string, userPatient) {

        try {
            console.log("creating client location patient");

            // clone patient
            var clientPatient = JSON.parse(JSON.stringify(userPatient));

            clientPatient.uid = userPatient.id;

            // create a new patient
            const docRef = await addDoc(collection(db, "clients", clientId, "locations", locationId, "patients"), clientPatient);

            clientPatient.id = docRef.id;

            PatientsService.updateClientLocationPatientIndexes(clientId, locationId, clientPatient.id);

            // now save the id to the userPatient
            if (!userPatient.clientUserIds) {
                userPatient.clientUserIds = {};
            }
            userPatient.clientUserIds[`${clientId}-${locationId}`] = docRef.id;
            PatientsService.updatePatient(userPatient);

            return clientPatient;

        } catch (error) {
            console.log("Error creating new client location patient: ", error);
            return null;
        }

    },

    async getClientLocationPatientByMobilePhoneNumberAndName(clientId: string, locationId: string, userPatient) {

        try {

            const phoneNumber = trimPhoneNumberWithoutCountryCode(userPatient.mobilePhoneNumber);

            const q = query(
                collection(db, "clients", clientId, "locations", locationId, "patients"),
                where("mobilePhoneNumber", "==", phoneNumber),
                where("lastName", "==", userPatient.lastName.trim()),
                where("firstName", "==", userPatient.firstName.trim())
            );
            
            const querySnapshot = await getDocs(q);

            let patient: any = null;

            if (querySnapshot.docs.length > 0) {
                const doc = querySnapshot.docs[0];

                patient = doc.data();
                patient.id = doc.id;

            }

            return patient;

        } catch (error) {
            console.log("Error getting client location patient: ", error);
            return null;
        }

    },

    async addToPatientAppointmentList(userPatient, appointment) {

        try {

            // get the patient that is saved under the client location
            let userId = userPatient.id;

            if (userPatient.clientUserIds && userPatient.clientUserIds[`${appointment.clientId}-${appointment.locationId}`]) {
                userId = userPatient.clientUserIds[`${appointment.clientId}-${appointment.locationId}`];
            }

            let clientLocationPatient = await PatientsService.getClientLocationPatient(appointment.clientId, appointment.locationId, userId);

            // if we did not find one, search again by using the mobile phone number
            if (!clientLocationPatient) {
                clientLocationPatient = await PatientsService.getClientLocationPatientByMobilePhoneNumberAndName(appointment.clientId, appointment.locationId, userPatient);
            }

            // if no patient was found create a new entry
            if (!clientLocationPatient) {
                clientLocationPatient = await PatientsService.createClientLocationPatient(appointment.clientId, appointment.locationId, userPatient);
            }

            // sets the last date the patient had an appointment
            let lastAppointmentDateHasChanged = false;
            const startDate = getDate(appointment.start);

            if (clientLocationPatient.lastAppointmentDate) {
                if (startDate.getTime() >= getDate(clientLocationPatient.lastAppointmentDate).getTime()) {
                    clientLocationPatient.lastAppointmentDate = new Date(startDate);
                    lastAppointmentDateHasChanged = true;
                }
            } else {
                clientLocationPatient.lastAppointmentDate = new Date(startDate);
                lastAppointmentDateHasChanged = true;
            }

            if (lastAppointmentDateHasChanged) {
                // last appointment date has to be saved for each location
                const docRef = doc(db, "clients", appointment.clientId, "locations", appointment.locationId, "patients", clientLocationPatient.id);
                await updateDoc(docRef, 
                    {
                        lastAppointmentDate: clientLocationPatient.lastAppointmentDate
                    }
                );
            }


            const doctor = await UsersService.getUser(appointment.clientId, appointment.calendar.userId);
            let doctorFullName = "";

            if (doctor) {
                doctorFullName = getFullUserName(doctor, true) as string;
            }

            // save a short appointment summary to the db root patients
            // then we can show a list of appointments on the patient app
            // without havving to fetch each appointment individually which is expensive
            const docRef = doc(db, "patients", userPatient.id, "appointments", appointment.id);
            await setDoc(docRef, 
                {
                    start: getDate(appointment.start),
                    doctorId: appointment.calendar.userId,
                    doctorFullName: doctorFullName,
                    calendarId: appointment.calendar.id,
                    visitMotiveId: appointment.visitMotive.id,
                    visitMotiveName: appointment.visitMotive.nameForPatient ? appointment.visitMotive.nameForPatient : appointment.visitMotive.name,
                    clientId: appointment.clientId,
                    locationId: appointment.locationId
                }
            );

        } catch (error) {
            console.log("Error adding appointment to patient appointments: ", error);
        }
    },

    async deleteFromPatientAppointmentList(appointmentId: string, patientId: string) {

        try {
            await deleteDoc(doc(db, "patients", patientId, "appointments", appointmentId));

        } catch(error) {
            console.log("Error deleteting from patient appointment list: ", error);
        }        
    },

    async deletePatientUser(userId: string) {

        return new Promise<void>((resolve, reject) => {

            const deletePatientUserFunc = httpsCallable(functions, "deletePatientUser");
            deletePatientUserFunc(
                {
                    userId: userId
                })
                .then(() => {

                    resolve();
                })
                .catch((error) => {
                    console.log("Error deleting patient user: ", error);
                    reject(error);
                });

        });
    },


    async updateClientLocationPatientIndexes(clientId: string, locationId: string, patientId: string) {

        return new Promise<void>((resolve, reject) => {

            const updatePatientIndexesFunc = httpsCallable(functions, "updatePatientIndexes");
            updatePatientIndexesFunc(
                {
                    clientId: clientId,
                    locationId: locationId,
                    patientId: patientId
                })
                .then(() => {

                    resolve();
                })
                .catch((error) => {
                    console.log("Error updating client location patient indexes: ", error);
                    reject(error);
                });

        });
    },

    getDocumentsLink(patientId: string, clientId: string, locationId: string) {
        return `https://cal.pickadoc.de/docs/${patientId}/${clientId}/${locationId}`;
    }

}

export default PatientsService;