import firebaseApp from "../components/database";
import LogItem, { LogItemType } from "../models/logItem";
import { getDiffInDays, isCrawlerUserAgent } from "../utils";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions(firebaseApp, 'europe-west3');

const LoggingService = {


    async log(logItemType: LogItemType, appointmentId: string | undefined | null, patientId: string | undefined | null, doctorId: string | undefined | null, message: string, clientId: string, locationId: string): Promise<void> {

        try {

            // don't log bots, crawlers..
            if (isCrawlerUserAgent()) return;

            // don't log debuggin stuff
            //if(message.toLowerCase().indexOf("kiriakos tzannis") > -1) return;

            const _patientId = patientId ?? "";
            const _appointmentId = appointmentId ?? "";
            const _doctorId = doctorId ?? "";

            const logItem = new LogItem("", _appointmentId, "", _patientId, _doctorId, "", logItemType, message);

            const logFunc = httpsCallable(functions, "log");
            await logFunc(
                {
                    logItemJson: logItem.toJSON(false),
                    clientId: clientId,
                    locationId: locationId
                });

        } catch (error) {
            console.log("Error logging to db: ", error);
        }
    },
    
    // 'id' can be the appointmentId if we are on the appointment page
    // or the doctorId if we are on the profile page of the doctor
    storeLastVisitTimestamp(id: string) {
        localStorage.setItem("lastVisit-" + id, Date.now().toString())
    },

    getLastVisitInDays(id: string): number {
        const lastVisitString = localStorage.getItem('lastVisit-' + id);
        
        let lastVisitDate: null | Date = null;
        let lastVisitInDays = -1;
        if (lastVisitString) {
            const now = new Date();
            lastVisitDate = new Date();
            lastVisitDate.setTime(parseInt(lastVisitString));

            lastVisitInDays = Math.floor( getDiffInDays(lastVisitDate, now));
        }
        
        return lastVisitInDays;
    }

}

export default LoggingService;