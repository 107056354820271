import Gender from "./gender";

export default class InstallationModel {

    userId: string = "";
    email: string = "";
    password: string = "";
    mobileNumber: string = "";

    gender: Gender = Gender.none;
    title: string = "";
    firstName: string = "";
    lastName: string = "";

    clientLocationName: string = "";
    customSenderName: string = "";

    street: string = "";
    city: string = "";
    postalCode: string = "";
    state: string = "";

    modus: "normal" | "basic" | "premium" | "tradeshow" = "normal"; // for tradeshows we can offer special bundles and free 30 days trials
}
