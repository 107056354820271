import React, { useState, useEffect, useRef } from 'react';


import firebaseApp from "./database";

import PatientsService from '../services/patientsService';
import Popup from "./popup";
import TermsPanel from "./termsPanel";
import AccountVerificationPanel from "./accountVerificationPanel";
import { trimPhoneNumber } from '../utils';
import LoggingService from '../services/loggingService';
import { LogItemType } from '../models/logItem';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { createUserWithEmailAndPassword, getAuth, linkWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';

const functions = getFunctions(firebaseApp, 'europe-west3');

export default function RegisterUserPanel(props) {

    const [mobilePhoneNumber, setMobilePhoneNumber] = useState("");
    const [gender, setGender] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [acceptTerms, setAcceptTerms] = useState(false);

    const [recaptchaSolved, setRecaptchaSolved] = useState(false);
    const [smsHasBeenSent, setSmsHasBeenSent] = useState(false);

    const [termsPopupVisible, setTermsPopupVisible] = useState(false);

    const mobilePhoneNumberRef = useRef(null);


    useEffect(() => {

        setSmsHasBeenSent(false);


        // Turn off phone auth app verification for testing        
        getAuth().settings.appVerificationDisabledForTesting = false;

        try {
            // init recaptcha verifier
            (window as any).recaptchaVerifier = new RecaptchaVerifier("recaptcha-container-register", {
                'size': 'normal',
                'callback': function (response) {
                    // reCAPTCHA solved, allow register.
                    console.log("recaptcha solved");
                    setRecaptchaSolved(true);
                },
                'expired-callback': function () {
                    console.log("recaptcha expired");
                    setRecaptchaSolved(false);
                }
            }, getAuth());
            
        } catch (error) {
            console.error(error);
        }

        (window as any).recaptchaVerifier.render().then(function (widgetId) {
            (window as any).recaptchaWidgetId = widgetId;
        }).catch((error) => {
            console.error(error);
        });


    }, []);


    // creates an email user
    // sends a SMS verification code
    // signs the new created email user out again
    function onRegisterFormSubmit(event) {

        if (event) {
            event.preventDefault();
        }

        // create an email user
        createUserWithEmailAndPassword(getAuth(), email, password)
            .then((result: any) => {

                if (!result && !result.user && !result.user.uid) return;

                const setPatientClaims = httpsCallable(functions, "setPatientClaims");
                setPatientClaims({ uid: result.user.uid });

                let phoneNumber = trimPhoneNumber(mobilePhoneNumber);

                // link the email auth account with the phone number auth account, otherwise firebase creates two different accounts
                const currentUser = getAuth().currentUser;
                if (!currentUser) throw ("Der Benutzer konnte nicht gefunden werden.");

                linkWithPhoneNumber(currentUser, phoneNumber, (window as any).recaptchaVerifier)
                    .then((confirmationResult) => {

                        (window as any).confirmationResult = confirmationResult;

                        getAuth().signOut();

                        setSmsHasBeenSent(true);

                        console.log("activation SMS has been sent");

                    })
                    .catch((error) => {
                        // Error occurred.
                        console.log(error);
                        getAuth().signOut();
                    });


                const patient = {
                    id: result.user.uid,
                    isOnlineUser: true,
                    firstName: firstName,
                    lastName: lastName,
                    gender: gender,
                    email: email,
                    mobilePhoneNumber: mobilePhoneNumber,
                    marketingAllowed: true,
                    reminderAllowed: true,
                    smsAllowed: true,
                    emailAllowed: true,
                    newPatient: true
                };
                PatientsService.createPatient(patient)
                    .then(() => {
                        //LoggingService.log(LogItemType.recallLandingPageLoaded, "", `New user registered ${patient.firstName} ${patient.lastName}`, "", "");
                    })
                    .catch((error) => {

                        const errorCode = error.code;

                        switch (errorCode) {

                            case "auth/invalid-phone-number":
                                alert("Bitte geben Sie eine gültige Handynummer an!");
                                break;

                            default:
                                alert(error.message);
                                break;
                        }


                        getAuth().signOut();
                    });

            })
            .catch((error) => {

                const errorCode = error.code;

                switch (errorCode) {
                    case "auth/email-already-in-use":
                    case "auth/email-already-exists":
                        alert("Diese Email Adresse ist bereits registriert! Bitte loggen Sie Sich ein oder benutzen Sie eine andere Email Adresse.");
                        break;

                    case "auth/invalid-email":
                        alert("Bitte geben Sie eine gültige Email Adresse an!");
                        break;

                    case "auth/invalid-password":
                    case "auth/weak-password":
                        alert("Ihr Passwort ist zu schwach! Es muss mindestens 6 Zeichen lang sein.");
                        break;

                    case "auth/invalid-phone-number":
                        alert("Bitte geben Sie eine gültige Handynummer an!");
                        break;

                    default:
                        alert(error.message);
                        break;
                }
            });

    }


    function onShowTermsClick() {
        setTermsPopupVisible(true);
    }

    function handleCloseTermsPopup() {
        setTermsPopupVisible(false);
    }


    return (
        smsHasBeenSent ?
            <AccountVerificationPanel
                _smsHasBeenSent={smsHasBeenSent}
                _email={email}
                _password={password}
                _mobilePhoneNumber={mobilePhoneNumber}
            />
            :
            <div className="kt-panel">
                <div className="kt-panel-content">
                    <div>
                        <div className="kt-center">Neu auf Pick-A-Doc?</div>
                        <form name="register" onSubmit={onRegisterFormSubmit}>

                            <input name="email" type="email" placeholder="E-Mail-Adresse" required value={email} onChange={e => setEmail(e.target.value)} />
                            <input name="password" type="password" placeholder="Passwort" required value={password} onChange={e => setPassword(e.target.value)} />

                            <div>
                                <label>Frau <input type="radio" name="gender" value="f" required checked={gender === "f"} onChange={e => setGender(e.target.value)} /></label>
                                <label>Herr <input type="radio" name="gender" value="m" checked={gender === "m"} onChange={e => setGender(e.target.value)} /></label>
                            </div>

                            <input name="firstname" type="text" placeholder="Vorname" required value={firstName} onChange={e => setFirstName(e.target.value)} />
                            <input name="lastname" type="text" placeholder="Nachname" required value={lastName} onChange={e => setLastName(e.target.value)} />

                            <input ref={mobilePhoneNumberRef} name="phone" type="tel" placeholder="Handynummer" required value={mobilePhoneNumber} onChange={e => setMobilePhoneNumber(e.target.value)} />
                            <div className="kt-explanation kt-center">Wir senden Ihnen einen <strong>Aktivierungs-Code per SMS</strong> zu.</div>

                            <div className="kt-center">
                                Ich erkläre mich mit den <span className="kt-link kt-underline" onClick={onShowTermsClick}>AGB</span> von Pick-A-Doc einverstanden.&nbsp;
                                <input name="acceptTerms" type="checkbox" required checked={acceptTerms} onChange={e => setAcceptTerms(e.target.checked)}></input>
                            </div>

                            <div id="recaptcha-container-register" className="kt-center"></div>

                            <input className={recaptchaSolved ? "kt-btn-big" : "kt-btn-big kt-btn-disabled"} type="submit" value="REGISTRIEREN" disabled={!recaptchaSolved} />
                        </form>

                        <div className="kt-history-back-link" onClick={e => window.history.back()}><i className="far fa-angle-left"></i> Zurück</div>
                    </div>
                </div>

                <Popup visible={termsPopupVisible} onClosePopup={handleCloseTermsPopup}>
                    <TermsPanel />
                </Popup>

            </div>


    );
}