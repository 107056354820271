import { getFunctions, httpsCallable } from "firebase/functions";
import firebaseApp from "./../components/database";
import { collection, doc, getDoc, getDocs, getFirestore, query, where } from "firebase/firestore";

const functions = getFunctions(firebaseApp, 'europe-west3');

const db = getFirestore(firebaseApp);

const CalendarsService = {

    async getCalendar(clientId: string, locationId: string, calendarId: string): Promise<any | null> {

        try {
            const docRef = doc(db, "clients", clientId, "locations", locationId, "calendars", calendarId);
            const document = await getDoc(docRef);

            if (document.exists()) {
                const calendar = document.data();
                calendar!.id = calendarId;

                return calendar;
            } else {
                console.log("getCalendar: No such document: " + calendarId);
            }

        } catch (error) {
            console.log("Error getting calendar: " + error);
        }

        return null;
    },

    async getCalendars(clientId: string, locationId: string, filterByAllowOnlineAppointments: boolean): Promise<any[] | null> {

        try {

            let q = query(collection(db, `clients/${clientId}/locations/${locationId}/calendars`));
            
            if (filterByAllowOnlineAppointments) {
                q = query(collection(db, `clients/${clientId}/locations/${locationId}/calendars`), where("allowOnlineAppointments", "==", true));                
            }

            const querySnapshot = await getDocs(q);

            const calendars: any[] = [];

            querySnapshot.forEach(doc => {

                if (doc.exists()) {
                    const calendar: any = doc.data();
                    calendar.id = doc.id;

                    if(calendar.license !== "disabled" && !calendar.isDeleted) {
                        calendars.push(calendar);
                    }
                }
            });

            return calendars;

        } catch (error) {
            console.log("Error getting calendars: ", error);
        }

        return null;
    },

    async generatePlaygroundLandingPage(clientName: string, visitMotiveText: string, email: string): Promise<void> {

        try {

            const generatePlaygroundLandingPageFunc = httpsCallable(functions, "generatePlaygroundLandingPage");
            const result: any = await generatePlaygroundLandingPageFunc(
                {
                    clientName: clientName,
                    visitMotiveText: visitMotiveText,
                    email: email
                });


        } catch (error) {
            console.log("Error generating playground landing page: ", error);
        }

    }

}

export default CalendarsService;