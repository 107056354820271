import React from 'react';


interface Props {
    show: boolean;
    message: string;
}

const LoadingCtrl:React.FC<Props> = ({show, message}) => {



    return (

        <div className="kt-loading-ctrl">

            <div className="kt-loading-overlay"></div>

            <div className="kt-message-wrapper">
                <div className="kt-loading-icon">
                    <i className="fas fa-circle-notch fa-spin"></i>
                </div>
                <div className="kt-message">{message}</div>
            </div>

        </div>

    );
}

export default LoadingCtrl;