import React, { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import TranslationsService from '../services/translationsService';

interface Props {
    label: string,
    selectedLanguage: string,
    onSelectionChange: (newLanguage: string) => void
}

const FlagsSelectCtrl: React.FC<Props> = ({ label, selectedLanguage, onSelectionChange }) => {

    const [hasError, setHasError] = useState(selectedLanguage === "");

    const handleSelectionChange = (e: SelectChangeEvent) => {
        const val = e.target.value === "none" ? "" : e.target.value as string;

        onSelectionChange(val);

        setHasError(val === "" || val === "none");
    }

    return (

        <FormControl fullWidth required error={hasError} className='kt-flags-select-ctrl'>
            <InputLabel id="demo-simple-select-label">{label}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedLanguage}
                label={label}
                onChange={handleSelectionChange}
                inputProps={{ required: true }}
                required
            >
                <MenuItem value="" disabled className='kt-flags-select-ctrl-item'>--- Bitte wählen Sie eine Sprache ---</MenuItem>
                {TranslationsService.languages.map((lang) =>
                    <MenuItem value={lang.key} className='kt-flags-select-ctrl-item'><img src={`../images/flags/${lang.key}.svg`} alt={lang.name}></img> {lang.name}</MenuItem>
                )}

            </Select>
            {hasError && <FormHelperText>Bitte wählen Sie eine Sprache</FormHelperText>}
        </FormControl>

    );
}

export default FlagsSelectCtrl;