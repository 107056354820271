import React from 'react';


interface Props {
    rating: number;
    readOnly: boolean;
    onRatingChange: (newValue: number) => void;
}

const StarsCtrl:React.FC<Props> = ({rating, readOnly, onRatingChange}) => {

    function handleRatingChange(newValue:number) {
        if(!readOnly){
            onRatingChange(newValue);
        }
    }

    function renderStars(){

        const stars = []  as any;

        for (let i = 0; i < 5; i++) {

            if(rating > i && rating < i+1){
                stars.push(<i key={i} onClick={() => handleRatingChange(i+1)} className="fas fa-star-half-alt"></i>);
            } else if(rating > i){
                stars.push(<i key={i} onClick={() => handleRatingChange(i+1)} className="fas fa-star"></i>);
            } else {
                stars.push(<i key={i} onClick={() => handleRatingChange(i+1)} className="far fa-star"></i>);
            }
        }

        return (
            <div className={readOnly ? `kt-stars-wrapper kt-readOnly` : `kt-stars-wrapper`}>
                {stars}
            </div>
        );
    }

    return (

        <div className="kt-stars-ctrl">
            {renderStars()}
            {/* <span className="kt-rating-value">
                {rating ? rating : "?"}
            </span>             */}
        </div>

    );
}

export default StarsCtrl;