import React, { useEffect } from 'react';
// import { analytics } from '../database';

export default function ContactPage() {

    // useEffect(()=>{
    //     analytics.logEvent("page_view", {page_title: "Legal Notice"});
    // },[]);

    return (
        <div className="kt-contactPage">

            <div className="kt-body">
                <div className="kt-panel">

                    <div className="kt-content-header">

                        <div className="kt-history-back-link" onClick={e => window.history.back()}><i className="far fa-angle-left"></i> Zurück</div>

                        <h2><strong>Kontakt</strong></h2>

                        <div>
                            <p>
                                <h3>Pickadoc GmbH</h3>
                                Oststrasse 34a<br />
                                40211 Düsseldorf<br />
                            </p>
                            <p>
                                Tel.: 0211 - 3029 3035<br />
                                <a href="mailto:info@pickadoc.de">info@pickadoc.de</a>
                            </p>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}