import firebaseApp from "./../components/database";

import PublicHoliday from "../models/publicHoliday";
import { collection, getDocs, getFirestore, query } from "firebase/firestore";


const db = getFirestore(firebaseApp);

const PublicHolidaysService = {

    async getPublicHolidays(country: string, state: string, startYear: number, numberOfYears: number): Promise<PublicHoliday[] | null> {

        try {

            const publicHolidayList: PublicHoliday[] = [];

            for (let y = 0; y < numberOfYears; y++) {

                const currentYear = startYear + y;

                let q = query(
                    collection(db, `publicHolidays`, country.toLowerCase(), currentYear.toString())
                );
                
                const querySnapshot = await getDocs(q);

                querySnapshot.forEach((doc) => {

                    const publicHoliday = new PublicHoliday();
                    publicHoliday.fromObject(doc.id, doc.data());

                    if(publicHoliday.states.includes(state.toUpperCase())){
                        publicHolidayList.push(publicHoliday);
                    }
                });

            }

            return publicHolidayList;

        } catch(error){
            console.log(`error in getPublicHolidays: ${error}`);
            return null;
        }

    }


}

export default PublicHolidaysService;