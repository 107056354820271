import React, { useState, useEffect } from 'react';

import UsersService from "../services/usersService";


interface Props {
    doctor?: any;
    src?: string;
    noCircle?: boolean;
    noBorder?: boolean;
    borderColor?: string;
    width?: string;
    fitImage?: boolean;
    alt?: string;
}

const Avatar:React.FC<Props> = ({doctor, src, noCircle, noBorder, borderColor, width, fitImage, alt}) => {

  const [url, setUrl] = useState("");

  useEffect(()=>{

        if(doctor) {

            UsersService.getUserAvatarUrl(doctor.clientId, doctor.id, "/images/doctor-avatar.jpg")
            .then((result) => {
                setUrl(result);
            });

        } else if(src) {
            setUrl(src);
        } else {
            setUrl("/images/doctor-avatar.jpg");
        }

  }, [src, doctor]);

  function getCss(){
      let css = "kt-avatar";

      css += noCircle ? " kt-no-circle" : "";
      css += noBorder ? " kt-no-border" : "";

      return css;
  }

  return (
    <div className={getCss()} style={{width: width, minWidth: width, height: width, borderColor: borderColor ?? "auto"}}>
        {fitImage ?
            <div style={{backgroundImage: `url(${url})`}}></div>
            :
            <img src={url} alt={alt} />
        }
    </div>
  );
}

export default Avatar;