import { getFunctions, httpsCallable } from "firebase/functions";
import InstallationModel from "../models/installationModel";
import firebaseApp from "../components/database";

const functions = getFunctions(firebaseApp, 'europe-west3');

const InstallationsService = {

    async startWizardInstallation(installationData: InstallationModel): Promise<boolean> {

        try {

            const installFunc = httpsCallable(functions, "startWizardInstallation");
            await installFunc(installationData);

            return true;

        } catch(error) {
            console.log("Error in startWizardInstallation: ", error);

            return false;
        }
    },

    async sendDemoSms(data: any): Promise<boolean> {

        try {

            const sendDemoSmsFunc = httpsCallable(functions, "sendDemoSms");
            await sendDemoSmsFunc(data);

            return true;

        } catch(error) {
            console.log("Error in sendDemoSms: ", error);

            return false;
        }
    }

}

export default InstallationsService;