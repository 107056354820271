import React, { useEffect, useRef, useState } from 'react';

interface Props {
    avatarImageurl?: string;
}

const AiPhoneChatCtrl: React.FC<Props> = ({avatarImageurl}) => {

    useEffect(() => {

        if (!(window as any).vapiInstance) {
            const g = document.createElement("script") as any;
            const s = document.getElementsByTagName("script")[0];
            g.src = "https://cdn.jsdelivr.net/gh/VapiAI/html-script-tag@latest/dist/assets/index.js";
            g.defer = true;
            g.async = true;
            s.parentNode?.insertBefore(g, s);

            g.onload = function () {
                if (!(window as any).vapiInstance) {
                (window as any).vapiInstance = (window as any).vapiSDK?.run({
                    apiKey: apiKey, // mandatory
                    assistant: assistant, // mandatory
                    config: buttonConfig, // optional
                });
            }
            };
        }
    }, []);

    var vapiInstance = null;
    const assistant = "60e373dd-c0f3-410e-9786-611135906df8"; // Substitute with your assistant ID
    const apiKey = "f7d77178-8eb4-4d81-bdf3-ef609108b5b7"; // Substitute with your Public key from Vapi Dashboard.
    const buttonConfig = {
        position: "bottom", // "bottom" | "top" | "left" | "right" | "top-right" | "top-left" | "bottom-left" | "bottom-right"
        offset: "50px", // decide how far the button should be from the edge
        width: "90px", // min-width of the button
        height: "90px", // height of the button
        idle: { // button state when the call is not active.
            color: `rgb(254, 140, 245)`,
            type: "round", // or "round"
            title: "Habe Sie eine Frage?", // only required in case of Pill
            subtitle: "Reden Sie mit der Dr.Petsas AI", // only required in case of pill
            icon: avatarImageurl ?? `https://unpkg.com/lucide-static@0.321.0/icons/phone.svg`,
        },
        loading: { // button state when the call is connecting
            color: `rgb(6, 255, 6)`,
            type: "round", // or "round"
            title: "Verbinde...", // only required in case of Pill
            subtitle: "Bitte warten", // only required in case of pill
            icon: `https://unpkg.com/lucide-static@0.321.0/icons/loader-2.svg`,
        },
        active: { // button state when the call is in progress or active.
            color: `rgb(255, 0, 0)`,
            type: "round", // or "round"
            title: "Anruf wird gestartet...", // only required in case of Pill
            subtitle: "Anruf beenden.", // only required in case of pill
            icon: `https://unpkg.com/lucide-static@0.321.0/icons/phone-off.svg`,
        },
    };


    return (
        <>

        </>
    );
}

export default AiPhoneChatCtrl;