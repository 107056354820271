import React from 'react';

export default function ThankYouPage() {

    return (<div className="kt-body">
        <div className="kt-panel" >
            <div className="kt-panel-content" >
                <h1>Vielen Dank! </h1>
                <h2>Wir freuen uns auf Ihren Besuch.</h2>
            </div>
        </div>
    </div>);

}