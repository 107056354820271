import React, { useEffect } from 'react';

import { scrollToTop } from "../../utils";

import TermsPanel from "../termsPanel";
// import { analytics } from '../database';

export default function TermsPage() {


    // useEffect(() => {
    //     analytics.logEvent("page_view", { page_title: "Terms" });
    // }, []);


    useEffect(() => {
        scrollToTop();
    });


    return (
        <div className="kt-termsPage">

            <div className="kt-body">
                <div className="kt-panel">

                    <div className="kt-content-header">

                        <div className="kt-history-back-link" onClick={e => window.history.back()}><i className="far fa-angle-left"></i> Zurück</div>

                        <TermsPanel />

                        <div className="kt-history-back-link" onClick={e => window.history.back()}><i className="far fa-angle-left"></i> Zurück</div>

                    </div>
                </div>
            </div>

        </div>
    );
}