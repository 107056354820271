import React, { useState, useEffect } from 'react';

import {Link} from "react-router-dom";

import { getAuth, onAuthStateChanged } from 'firebase/auth';

const LoginRegisterPage: React.FC = () => {

    const [authUser, setAuthUser] = useState<any|null>(null);

    useEffect(() => {
        return onAuthStateChanged(getAuth(), function(user) {
            if (user) {
                // User is signed in.
                setAuthUser(user);
            } else {
                // User is signed out.
                setAuthUser(null);
            }
          });

    }, []);


    return (

        <div className="kt-loginPage">

            <div className="kt-body">

                {(authUser && authUser.phoneNumber) &&
                <div className="kt-panel">
                    <div className="kt-panel-content">
                        <div><h2>Sie sind angemeldet.</h2></div>
                        <div className="kt-history-back-link" onClick={e => window.history.back()}><i className="far fa-angle-left"></i> Zurück</div>
                    </div>
                </div>}

                {!(authUser && authUser.phoneNumber) &&
                <div className="kt-panel">
                    <div className="kt-panel-content">

                        <div className="kt-center">Neu auf Pick-A-Doc?</div>
                        <Link to="/register" className="kt-btn-big">REGISTRIEREN</Link>

                    </div>
                </div>}

                {!(authUser && authUser.phoneNumber) &&
                <div className="kt-panel">
                    <div className="kt-panel-content">

                        <div className="kt-center">Sie haben bereits ein Pick-A-Doc Konto?</div>
                        <Link to="/login" className="kt-btn-big kt-btn-secondary-color">ANMELDEN</Link>

                        <div className="kt-history-back-link" onClick={e => window.history.back()}><i className="far fa-angle-left"></i> Zurück</div>

                    </div>
                </div>}

            </div>

        </div>
    );
}

export default LoginRegisterPage;