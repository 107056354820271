import { getDate } from "../utils";

class Prediction {

    id: string = "";

    audioId: string = "";

    inputText: string = "";
    translatedText: string = "";
    targetLanguage: string = "";

    videoUrl: string = "";

    email: string = "";

    createdAt: Date | null = new Date();


    clone(): Prediction {
        const p = new Prediction();

        p.fromObject(this.id, this.toJSON());

        return p;
    }



    toJSON(): object {

        const obj: any = {};

        obj.id = this.id;
        obj.audioId = this.audioId;

        obj.inputText = this.inputText;
        obj.translatedText = this.translatedText;
        obj.targetLanguage = this.targetLanguage;

        obj.videoUrl = this.videoUrl;
        obj.email = this.email;
        obj.createdAt = this.createdAt;

        return obj;

    }

    fromObject(id:string|null, o:any) {

        this.id = id ?? o.id;

        this.audioId = o.audioId ?? "";

        this.inputText = o.inputText ?? "";
        this.translatedText = o.translatedText ?? "";
        this.targetLanguage = o.targetLanguage ?? "";

        this.videoUrl = o.videoUrl ?? "";
        this.email = o.email ?? "";

        this.createdAt = o.calledAt ? (getDate(o.calledAt) ?? new Date()) : new Date();
    }


};

export default Prediction;