
export class VideoGroup {
    videoGroupId: string = "";
}

export class CalendarVideoGroup extends VideoGroup {
    calendarId: string = "";
}



class LandingPageData {
    headline: string = "";
    image: string = "";
    beforeAfterImages: {before: string, after: string}[] = [];
    description: string = "";
    videoGroupId: string = "";
    videoGroups: CalendarVideoGroup[] = [];
    showRatings: boolean = false;
    showButtons: boolean = true; // buttons like postpone, book appointment or cancel appointment

    clone(): LandingPageData {
        const l = new LandingPageData();

        l.headline = this.headline;
        l.image = this.image;
        l.beforeAfterImages = [...this.beforeAfterImages];
        l.description = this.description;
        l.videoGroupId = this.videoGroupId;
        l.videoGroups = [...this.videoGroups];
        l.showRatings = this.showRatings;
        l.showButtons = this.showButtons;

        return l;
    }

    toJSON(): object {
        return {
            headline: this.headline,
            image: this.image,
            beforeAfterImages: this.beforeAfterImages,
            description: this.description,
            videoGroupId: this.videoGroupId,
            videoGroups: this.videoGroups,
            showRatings: this.showRatings,
            showButtons: this.showButtons
        }
    }

    fromObject(o:any) {

        this.headline = o.headline ?? "";
        this.image = o.image ?? "";
        this.beforeAfterImages = o.beforeAfterImages ? [...o.beforeAfterImages] : [];
        this.description = o.description ?? "";
        this.videoGroupId = o.videoGroupId ?? "";
        this.videoGroups = o.videoGroups ? [...o.videoGroups] : [];
        this.showRatings = o.showRatings === true;
        this.showButtons = o.showButtons === undefined ? true : o.showButtons === true;
    }

};



export default LandingPageData;