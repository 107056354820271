import React, { useState, useEffect, useRef } from 'react';

import PatientsService from '../services/patientsService';
import AppointmentsService from '../services/appointmentsService';
import { getAuth, linkWithPhoneNumber, RecaptchaVerifier, signInWithEmailAndPassword } from 'firebase/auth';


interface Props {
    _smsHasBeenSent: boolean,
    _mobilePhoneNumber: string,
    _email: string,
    _password: string
}


const AccountVerificationPanel: React.FC<Props> = ({ _smsHasBeenSent, _mobilePhoneNumber, _email, _password }) => {

    const [mobilePhoneNumber, setMobilePhoneNumber] = useState("");

    const [confirmationCode, setConfirmationCode] = useState("");

    const [recaptchaSolved, setRecaptchaSolved] = useState(false);
    const [smsHasBeenSent, setSmsHasBeenSent] = useState(false);

    const mobilePhoneNumberRef = useRef(null);


    useEffect(() => {

            if (!_smsHasBeenSent) {
                
                try {

                    // init recaptcha verifier
                    (window as any).recaptchaVerifier = new RecaptchaVerifier("recaptcha-container-verification", {
                        'size': 'normal',
                        'callback': function (response) {
                            // reCAPTCHA solved, allow register.
                            console.log("recaptcha callback");
                            setRecaptchaSolved(true);
                        },
                        'expired-callback': function () {
                            console.log("recaptcha expired-callback");
                            setRecaptchaSolved(false);
                        }
                    }, getAuth());

                    (window as any).recaptchaVerifier.render().then(function (widgetId) {
                        (window as any).recaptchaWidgetId = widgetId;
                    });

                } catch (error: any) {
                    console.error(error);
                }
            }

    }, [_smsHasBeenSent]);

    useEffect(() => {
        setMobilePhoneNumber(_mobilePhoneNumber);
    }, [_mobilePhoneNumber]);

    useEffect(() => {
        setSmsHasBeenSent(_smsHasBeenSent);
    }, [_smsHasBeenSent]);


    // signs the user in with email and password for linking accounts
    // sends a SMS verification code
    // logs the user out again
    function onSendVerficationCodeFormSubmit(event) {
console.log(_password);
console.log(_email);
console.log(_mobilePhoneNumber);

        if (event) {
            event.preventDefault();
        }

        let phoneNumber = mobilePhoneNumber;
        if (phoneNumber && typeof phoneNumber === "string" && phoneNumber.slice(0, 1) === "0") {
            phoneNumber = "+49" + phoneNumber.slice(1, phoneNumber.length);
        }

        // link the email auth account with the phone number auth account, otherwise firebase creates two different accounts
        const currentUser = getAuth().currentUser;
        if (!currentUser) throw ("Der Benutzer konnte nicht gefunden werden.");

        linkWithPhoneNumber(currentUser, phoneNumber, (window as any).recaptchaVerifier)
            .then((confirmationResult) => {

                (window as any).confirmationResult = confirmationResult;

                setSmsHasBeenSent(true);

                getAuth().signOut();

                console.log("activation SMS has been sent");

            })
            .catch((error) => {
                // Error occurred.
                console.log(error);

                getAuth().signOut();
            });


    }

    function onConfirmFormSubmit(event) {
        if (event) {
            event.preventDefault();
        }

        // now verify the SMS code
        (window as any).confirmationResult.confirm(confirmationCode).then(async function () {

            // a confirm does not sign in the user again, so we do it here manually
            const result = await signInWithEmailAndPassword(getAuth(), _email, _password);

            const userId = result.user?.uid;
            if (!userId) return;

            const patient = await PatientsService.getPatient(userId);

            // update patients mobile phone number if it has changed
            if (patient.mobilePhoneNumber !== mobilePhoneNumber) {
                patient.mobilePhoneNumber = mobilePhoneNumber;

                await PatientsService.updatePatient(patient);
            }

            // now check if we have to merge appointments from a temporary patient
            const appointments = await AppointmentsService.getAppointments(patient.mobilePhoneNumber);

            if (appointments && appointments.length > 0) {
                await AppointmentsService.moveUserAppointments(patient.mobilePhoneNumber, userId);

                // now that we moved the appointments
                // we can delete the temporary patient user
                PatientsService.deletePatientUser(patient.mobilePhoneNumber);
            }


            console.log("phone number is now verified");

        }).catch(function (error) {
            console.log("could not verify code: " + error);

            switch (error.code) {
                case "auth/account-exists-with-different-credential":
                case "auth/credential-already-in-use":
                    // phone number already registered
                    alert("Diese Handynummer ist schon mit einem Konto verknüpft. Bitte loggen Sie Sich ein oder benutzen Sie eine andere Handynummer.");
                    break;

                default:
                    // User couldn't sign in (bad verification code?)
                    alert("Wir konnten Sie mit diesem Code nicht verifizieren. Bitte versuchen Sie es erneut.");
                    break;
            }

        });
    }



    return (

        <div className="kt-panel" >
            <div className="kt-panel-content" >
                <div className="kt-center" > Ihr Account muss noch per SMS verifiziert werden.</div>

                <div>
                    {
                        smsHasBeenSent ?
                            <div>
                                <div className="kt-center" > Wir haben Ihnen soeben einen Aktivierungscode an die {mobilePhoneNumber} geschickt.</div>
                                <form name="confirm" onSubmit={onConfirmFormSubmit} >
                                    <input name="confirmationCode" type="text" placeholder="Aktivierungscode aus der SMS" required value={confirmationCode} onChange={e => setConfirmationCode(e.target.value)} />

                                    <input className="kt-btn-big" type="submit" value="BESTÄTIGEN" />
                                </form>
                            </div>
                            :
                            <form name="register" onSubmit={onSendVerficationCodeFormSubmit} >

                                <input ref={mobilePhoneNumberRef} name="phone" type="tel" placeholder="Handynummer" required value={mobilePhoneNumber} onChange={e => setMobilePhoneNumber(e.target.value)} />
                                <div className="kt-explanation kt-center" > Wir senden Ihnen erneut einen <strong > Aktivierungs - Code per SMS </strong> zu.</div >

                                <div id="recaptcha-container-verification" className="kt-center"></div>

                                <input className={recaptchaSolved ? "kt-btn-big" : "kt-btn-big kt-btn-disabled"} type="submit" value="CODE SENDEN" disabled={!recaptchaSolved} />
                            </form>
                    }
                </div>

            </div>


        </div>

    )
}

export default AccountVerificationPanel;